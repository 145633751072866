import { gql } from '@apollo/client';
import legacyCartFragment from '../fragments/legacyCart';

const removeFromLegacyCartMutation = gql`
  mutation RemoveProductFromLegacyCart($productId: String!, $basketRowId: String) {
    removeProductFromLegacyCart(productId: $productId, basketRowId: $basketRowId) {
      status
      message
      response {
        ...legacyCartFragment
      }
    }
  }
  ${legacyCartFragment}
`;

export default removeFromLegacyCartMutation;
