const FEATURE_FLAG = {
  PRE_DC_CHECKOUT_MIDID: 'pre_dc_checkout_mitid',
  TRADE_IN: 'trade_in',
  STOCK_STATUS_PRODUCT_PAGE: 'stockstatus_product_page',
  FULL_RESPONSE_CACHE: 'full_response_cache',
  FORCE_INVOICE: 'force_invoice',
  SHIPPING_COUNTDOWN: 'shipping_countdown',
  DISABLE_GLOBAL_HEADER: 'disable_global_header',
  HIDE_CWA_ERROR_MESSAGE: 'hide_cwa_error_message',
  SPLIT_TESTING_ENABLED: 'split_testing_enabled',
  ALD_DISABLED: 'disable_account_level_discount',
};

export default FEATURE_FLAG;
