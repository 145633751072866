import { useQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';
import getUnifiedCartQuery from 'apollo/queries/getUnifiedCart';
import addCartToWindow from '../barilliance/addCartToWindow';

/**
 * Custom Unified Cart hook to wrap all logic related to the unfied cart (DC + Legacy)
 */
const useUnifiedCart = (opt) => {
  const previousData = useRef(null);
  const {
    data: unifiedCartData,
    loading: unifiedCartLoading,
    error: unifiedCartError,
  } = useQuery(getUnifiedCartQuery, { ssr: false });

  useEffect(() => {
    addCartToWindow(unifiedCartData?.unifiedCart);
  }, [unifiedCartData]);

  useEffect(() => {
    if (opt?.offersChanged?.callback) {
      const offerIds = new Set(
        (unifiedCartData?.unifiedCart?.offers || []).map(({ offerId }) => offerId).filter(Boolean),
      );
      const shouldUpdateList =
        !previousData.current ||
        offerIds.size !== previousData.current.size ||
        Array.from(offerIds).some((offerId) => !previousData.current.has(offerId));

      if (shouldUpdateList) {
        previousData.current = offerIds;
        opt.offersChanged.callback();
      }
    }
  }, [unifiedCartData, ...(opt?.offersChanged?.deps || [])]);

  return {
    unifiedCartData,
    unifiedCartLoading,
    unifiedCartError,
  };
};

export default useUnifiedCart;
