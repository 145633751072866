import { useEffect } from 'react';
import SOURCE_SYSTEMS from 'constants/sourceSystems';
import useLegacyCart from './useLegacyCartHook';
import useCart from './useCartHook';
import useUnifiedCart from './useUnifiedCartHook';
import dataLayer from '../dataLayer/dataLayer';
import MENU_EVENTS from '../../constants/menuEvents';

/**
 * Initialize the listeners required for the Menu Cart
 */

const useMenuCart = () => {
  const { legacyCartData, removeFromLegacyCart, removeBasketRowFromLegacyCart } = useLegacyCart();
  const { cartData, removeFromCart } = useCart();
  const { unifiedCartData } = useUnifiedCart();

  useEffect(() => {
    const removeAccessoryOrAddonEvent = (event) => {
      const { id: productId, productRowId: basketRowId, sourceSystem } = event.detail;

      if (sourceSystem === SOURCE_SYSTEMS.LEGACY) {
        removeFromLegacyCart({ variables: { basketRowId, productId } }).then(() =>
          dataLayer.legacyProductRemoved({ basketRowId, productId }, legacyCartData),
        );
      }

      if (sourceSystem === SOURCE_SYSTEMS.DC) {
        removeFromCart({ variables: { cartOfferingId: productId } }).then(() =>
          dataLayer.productRemoved(productId, cartData),
        );
      }
    };

    const removeRowEvent = (event) => {
      const { rowId: basketRowId, sourceSystem } = event.detail;
      const previousOffersData = unifiedCartData.unifiedCart?.offers || [];
      const nextOffers = previousOffersData.filter((offer) => offer.offerId !== basketRowId);

      if (sourceSystem === SOURCE_SYSTEMS.LEGACY) {
        removeBasketRowFromLegacyCart({ variables: { basketRowId } }).then(() => {
          dataLayer.legacyProductRemoved({ basketRowId }, legacyCartData);
          window.dispatchEvent(
            new CustomEvent(MENU_EVENTS.ON_ROW_REMOVED, { detail: { offers: nextOffers, sourceSystem } }),
          );
        });
      }
      if (sourceSystem === SOURCE_SYSTEMS.DC) {
        removeFromCart({ variables: { cartOfferingId: basketRowId } }).then(() => {
          dataLayer.productRemoved(basketRowId, cartData);
          window.dispatchEvent(
            new CustomEvent(MENU_EVENTS.ON_ROW_REMOVED, { detail: { offers: nextOffers, sourceSystem } }),
          );
        });
      }
    };

    window.addEventListener(MENU_EVENTS.REMOVE_ACCESSORY, removeAccessoryOrAddonEvent);
    window.addEventListener(MENU_EVENTS.REMOVE_ADDON, removeAccessoryOrAddonEvent);
    window.addEventListener(MENU_EVENTS.REMOVE_ROW, removeRowEvent);

    return () => {
      window.removeEventListener(MENU_EVENTS.REMOVE_ACCESSORY, removeAccessoryOrAddonEvent);
      window.removeEventListener(MENU_EVENTS.REMOVE_ADDON, removeAccessoryOrAddonEvent);
      window.removeEventListener(MENU_EVENTS.REMOVE_ROW, removeRowEvent);
    };
  }, [removeBasketRowFromLegacyCart, removeFromCart, removeFromLegacyCart, unifiedCartData, legacyCartData, cartData]);
};

export default useMenuCart;
