import { gql } from '@apollo/client';
import legacyCartFragment from '../fragments/legacyCart';

const legacyCartQuery = gql`
  query LegacyCart {
    legacyCart {
      ...legacyCartFragment
    }
  }
  ${legacyCartFragment}
`;

export default legacyCartQuery;
