// TODO: Move to 3style and fix theme so that we don't have to explicitly
//       set the css color on a <Button /> in business.
const businessTheme = {
  palette: {
    background: {
      primary: '#161f3f',
      secondary: '#0b4d87',
      tertiary: '#ffffff',
    },
    font: {
      primary: '#000000',
      secondary: '#ffffff',
    },
    button: '#EC0677',
    checkbox: '#1c7cd5',
    title: '#0b4d87',
    divider: '#DDDDDD',
  },
};

export default businessTheme;
