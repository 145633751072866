import PropTypes from 'prop-types';
import ErrorPage from './ErrorPage';

const pageTitle = 'Siden kan ikke vises (500)';
const text = 'Vi oplever desværre lidt problemer med vores hjemmeside. Men vi arbejder benhårdt på at løse problemet.';

const ErrorPage500 = ({ error }) => <ErrorPage code={500} text={text} error={error} pageTitle={pageTitle} />;

ErrorPage500.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object.isRequired,
};

export default ErrorPage500;
