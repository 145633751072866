const events = {
  PRODUCT_IMPRESSIONS: 'eec.impressionView',
  PRODUCT_CLICKS: 'eec.impressionClick',
  PRODUCT_DETAIL_IMPRESSIONS: 'eec.detail',
  ADD_TO_CART: 'eec.add',
  REMOVE_FROM_CART: 'eec.remove',
  CHECKOUT: 'eec.checkout',
  PURCHASE: 'eec.purchase',
  VIRTUAL_PAGE_VIEW: 'VirtualPageview',
  CHANGE_PAYMENT_BUTTON: 'ChangePaymentButton',
  NO_UPFRONT_BUTTON: 'NoUpfrontButton',
  FULL_UPFRONT_BUTTON: 'FullUpfrontButton',
  MONTHLY_INSTALLMENT_PERIOD: 'monthlyinstallmentperiod',
  CHANGE_SUBSCRIPTION_BUTTON: 'ChangeSubscriptionButton',
  PRE_DC_CHECKOUT: 'checkout',
  LOGIN: 'loginTracking',
  EXTERNAL_INSTALLMENT_CHECK: 'externalInstallmentCheck',
  INTERNAL_INSTALLMENT_CHECK: 'internalInstallmentCheck',
};

export default events;
