import { useCallback, useState, useEffect } from 'react';
import loadable from '@loadable/component';
import { Link, useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { error as logError } from '@hi3g-access/client-logger';
import useMenuCart from 'utils/hooks/useMenuCartHook';
import useUnifiedCart from 'utils/hooks/useUnifiedCartHook';
import runtimeConfig from '../utils/config';
import FETCH_STATE, { fetchStateType } from '../constants/fetchState';

const LoadedMenu = loadable(() => import('@3dk/3dk-menu'), { ssr: false });

const { MENU_BASE_URI } = runtimeConfig;
const ACCESSORY_PAGE_BLOCK_ID = 17672;
// business params are configured the same way as in Monolith\Tre.Web\Controllers\PageControllerBase.cs GetMenu
const BUSINESS_PARAMS = 'isBusiness=true&context=business';
const CONSUMER_PARAMS = `consumerStartPageId=${ACCESSORY_PAGE_BLOCK_ID}`;
const getMenuConfigUrl = (isBusiness) =>
  `${MENU_BASE_URI}/menuapi/api/menu?${isBusiness ? BUSINESS_PARAMS : CONSUMER_PARAMS}`;

const updateBasketForMenu = (unifiedCartData) => {
  const { offers, priceSummary, tradeInProducts, accountLevelDiscount } = unifiedCartData?.unifiedCart || {};
  return {
    offers,
    priceSummary,
    tradeInProducts,
    accountLevelDiscount,
  };
};

const Menu = (props) => {
  const { menuFetchState, setMenuFetchState, showMenu, isBusiness } = props;
  const history = useHistory();
  const location = useLocation();
  const [menuConfigData, setMenuConfigData] = useState({});
  const { unifiedCartData, unifiedCartLoading } = useUnifiedCart();
  useMenuCart();

  useEffect(() => {
    fetch(getMenuConfigUrl(isBusiness))
      .then((response) => {
        if (!response.ok) {
          logError('Network response while trying to fetch menu config was not ok');
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          setMenuConfigData({ ...result, business: isBusiness });
          setMenuFetchState(FETCH_STATE.SUCCESS);
        } else {
          setMenuFetchState(FETCH_STATE.FAILED);
        }
      });
  }, [isBusiness, setMenuFetchState]);

  // The callback ref will be called when the referenced node is mounted
  // This is to update the cart in the menu
  const menuRef = useCallback(
    (node) => {
      if (node !== null && !unifiedCartLoading) {
        const cart = updateBasketForMenu(unifiedCartData);
        node.onCartUpdated(null, cart);
      }
    },
    [unifiedCartData, unifiedCartLoading],
  );

  return (
    <>
      {showMenu && menuFetchState === FETCH_STATE.SUCCESS && (
        <LoadedMenu
          checkoutButtonComponent={Link}
          history={history}
          location={location}
          {...menuConfigData}
          isCartEditable
          ref={menuRef}
          // this is only used for initial data, since the menu doesn't re-render on prop changes
          // the cart is updated through the above callback function (menuRef)
          cart={updateBasketForMenu(unifiedCartData)}
        />
      )}
    </>
  );
};

Menu.propTypes = {
  menuFetchState: fetchStateType.isRequired,
  setMenuFetchState: PropTypes.func.isRequired,
  showMenu: PropTypes.bool.isRequired,
  isBusiness: PropTypes.bool,
};

Menu.defaultProps = {
  isBusiness: false,
};

export default Menu;
