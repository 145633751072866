import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/* Will rest the scroll position to 0 on history change */
const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      const { hash } = history.location;
      if (hash) {
        const elementId = hash.slice(1);
        const anchorElement = document.getElementById(elementId);
        anchorElement?.scrollIntoView();
      } else {
        window.scrollTo(0, 0);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default ScrollToTop;
