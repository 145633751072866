import { gql } from '@apollo/client';
import legacyCartFragment from '../fragments/legacyCart';

const addProductToLegacyCartMutation = gql`
  mutation addProductToLegacyCart(
    $items: [String!]!
    $upfront: Float
    $replaceBasket: Boolean
    $bundledProducts: [[String!]]
    $claimTradeIn: Boolean
  ) {
    addProductToLegacyCart(
      items: $items
      upfront: $upfront
      replaceBasket: $replaceBasket
      bundledProducts: $bundledProducts
      claimTradeIn: $claimTradeIn
    ) {
      status
      message
      response {
        ...legacyCartFragment
      }
    }
  }
  ${legacyCartFragment}
`;

export default addProductToLegacyCartMutation;
