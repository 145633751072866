import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const initI18n = (globalTexts) => {
  i18n.use(initReactI18next).init({
    lng: 'da',
    ns: globalTexts.namespaces,
    defaultNS: globalTexts.defaultNamespace,
    fallbackNS: globalTexts.defaultNamespace,
    resources: { da: globalTexts.resources },
  });
};

export default initI18n;
