const ORDER_DECISIONS = {
  ACCEPT: 'ACCEPT',
  CONTROL: 'CONTROL',
  INVALID: 'INVALID',
  REJECT: 'REJECT',
  REJECT_EXCEED_LIMIT: 'REJECT_EXCEED_LIMIT',
  REJECT_SUBSCRIPTION_VOICE: 'REJECT_SUBSCRIPTION_VOICE',
  REJECT_SUBSCRIPTION_DATA: 'REJECT_SUBSCRIPTION_DATA',
  REJECT_CWA: 'REJECT_CWA',
  REJECT_EXCEED_CWA_LIMIT: 'REJECT_EXCEED_CWA_LIMIT',
  REJECT_CWA_NOT_FOUND: 'REJECT_CWA_NOT_FOUND',
  REJECT_EXCEED_SUBSCRIPTION_LIMIT: 'REJECT_EXCEED_SUBSCRIPTION_LIMIT',
};

export default ORDER_DECISIONS;
