import { gql } from '@apollo/client';

const linkFragment = gql`
  fragment LinkFragment on Link {
    text
    linkType
    url
    externalLink
    internalLink {
      slug
      pageType
      type
    }
    assetLink {
      url
    }
    buttonColor
    oneClickDevice
    oneClickAccessories
    oneClickSubscriptions
    oneClickVas
    anchorLink
  }
`;

export default linkFragment;
