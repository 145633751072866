import { gql } from '@apollo/client';
import legacyCartFragment from '../fragments/legacyCart';

const updateAccountLevelDiscountMutation = gql`
  mutation updateAccountLevelDiscount {
    updateAccountLevelDiscount {
      ...legacyCartFragment
    }
  }
  ${legacyCartFragment}
`;

export default updateAccountLevelDiscountMutation;
