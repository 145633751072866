import { UNIFIED_CART_RECOVERY } from '../../constants/urls';

/**
 * Sets the window.cart object for Barilliance to track abandoned carts.
 * Abandoned carts occur when a customer initiates the checkout process but doesn't complete it.
 * Barilliance uses the information from the cart contents, along with customer details, to offer the customer an
 * option to resume the abandoned checkout at a later point.
 */
const addCartToWindow = (unifiedCart) => {
  if (typeof window === 'undefined' || !unifiedCart) return;

  const { offers, dcCartId, legacyCartId } = unifiedCart;
  const params = new URLSearchParams({ cartId: dcCartId, legacyCartId });

  const barillianceOffers = offers?.map((offer) => ({
    id: offer.id,
    psid: offer.psid,
    title: offer.title,
    image: offer.image,
    offerType: offer.offerType,
    subOfferType: offer.subOfferType,
    upfrontPrice: offer.upfrontPrice,
    monthlyPrice: offer.monthlyPrice,
    isInstallment: offer.isInstallment,
  }));

  window.cart = {
    recoverUrl: `${window.location.origin.concat(UNIFIED_CART_RECOVERY)}?${params.toString()}`,
    offers: barillianceOffers ?? [],
  };
};

export default addCartToWindow;
