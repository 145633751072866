import { error as logError } from '@hi3g-access/client-logger';

const errorHandler = {
  getResponseBody: (apolloError) => {
    let responseBody = {};
    apolloError?.graphQLErrors?.forEach((e) => {
      responseBody = e?.extensions?.response?.body ?? {};
    });

    return responseBody;
  },
  logGraphQLErrors: (graphQLErrors) =>
    graphQLErrors?.forEach((e) => {
      const { message, path, extensions } = e;
      const { code } = extensions || {};

      logError(
        `[GraphQL error]: Message: ${message}, Code: ${code}, Path: ${path}, Extensions: ${
          extensions && JSON.stringify(extensions)
        }`,
      );
    }),
  is404: (error) =>
    error?.graphQLErrors?.some((graphQLError) => {
      const { code } = graphQLError.extensions;
      return code === 404;
    }),
  // Check if error response has error code in list of validation errors
  hasValidationError: (validationErrors, errorCode) => {
    if (!validationErrors) return false;

    return validationErrors.some((validationError) => validationError.code === errorCode);
  },
  getPaymentValidationsErrorCodes: () => Object.values(errorHandler.errorResponseCodes.PAYMENT),
  errorResponseCodes: {
    INCOMPLETE_CHECKOUT: 1000, // (e-mail, phone number etc. missing)
    MISSING_TAXPAYER_ID_FOR_INSTALLMENT: 1001,

    CREDIT_CHECK_REJECTED: 7000,
    MANUAL_CREDIT_CHECK_REQUIRED: 7001,
    ACCESSORIES_NOT_ALLOWED: 7002,
    CREDIT_LIMIT_EXCEEDED: 7100,

    INVALID_FORMAT_ON_TAXPAYER_ID: 7778, // should be 10 digits
    INVALID_PHONE_NUMBER: 7779, // should be 8 or 10 digits, must start with 45 if 10 digits (ignoring "+", "-", and whitespace)

    PAYMENT: {
      MISMATCHING_PRICES: 2000,
      FAILED_PAYMENT: 2001,
      NON_AUTHORIZED_PAYMENT: 2002,
      PAYMENT_REF_NOT_MATCHING_ORDER: 2003,
      PAYMENT_REF_EXISTS: 2004,
      PAYMENT_REF_MISSING: 2005,
      PAYMENT_ERROR: 2099,
    },
  },
};

export default errorHandler;
