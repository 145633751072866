export const addToCartEvent = (products, addType) => ({
  ecommerce: {
    currencyCode: 'DKK',
    add: {
      ...(addType ? { addType } : {}),
      products,
    },
  },
});

export const removeFromCartEvent = (products) => ({
  ecommerce: {
    currencyCode: 'DKK',
    remove: {
      products,
    },
  },
});

export const impressionEvent = (impressions) => ({
  ecommerce: {
    impressions,
  },
});

export const detailsView = (products) => ({
  ecommerce: {
    currencyCode: 'DKK',
    detail: {
      products,
    },
  },
});

export const clickOnListItemEvent = (products, list) => ({
  ecommerce: {
    click: {
      actionField: {
        list,
      },
      products,
    },
  },
});

export const ecommerceCheckoutEvent = (step, products, checkoutType, options = {}) => ({
  ecommerce: {
    checkout: {
      checkoutType,
      actionField: {
        step,
        ...options,
      },
      products,
    },
  },
});

export const purchaseEvent = (products, actionField) => ({
  ecommerce: {
    currencyCode: 'DKK',
    purchase: {
      ...(actionField ? { actionField: { ...actionField } } : {}),
      products,
    },
  },
});
