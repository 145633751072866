import Cookies from 'js-cookie';

const ORDER_REFERENCE_COOKIE = 'orderReference';
const BYPASS_UNSUPPORTED_BROWSER = 'BypassUnsupportedBrowser';
const CUSTOMER_TYPE_COOKIE = 'customerType';
const ACCEPTED_CUSTOMER_CONSENT_COOKIE = 'acceptedCustomerConsent';
const LEGACY_BASKET_COOKIE_KEY = 'Basket.Consumer';

const expires = (minutes) => new Date(new Date().getTime() + 60 * 1000 * minutes);

const getOrderReference = () => Cookies.get(ORDER_REFERENCE_COOKIE);

const setOrderReference = (orderReference) => {
  const inOneHour = expires(60);
  Cookies.set(ORDER_REFERENCE_COOKIE, orderReference, {
    expires: inOneHour,
  });
};

const removeOrderReference = () => Cookies.remove(ORDER_REFERENCE_COOKIE);

const getCustomerTypeCookie = () => Cookies.get(CUSTOMER_TYPE_COOKIE);

const setCustomerTypeCookie = (customerType) => {
  const inOneHour = expires(30);
  Cookies.set(CUSTOMER_TYPE_COOKIE, customerType, { expires: inOneHour });
};

const getBypassUnsupportedBrowser = () => Cookies.get(BYPASS_UNSUPPORTED_BROWSER);

const setBypassUnsupportedBrowser = (value) => Cookies.set(BYPASS_UNSUPPORTED_BROWSER, value);

const getAcceptedCustomerConsent = () => Cookies.get(ACCEPTED_CUSTOMER_CONSENT_COOKIE);

const setAcceptedCustomerConsent = (consent) => {
  const inOneHour = expires(60);
  return Cookies.set(ACCEPTED_CUSTOMER_CONSENT_COOKIE, consent, {
    expires: inOneHour,
  });
};

const getLegacyCartCookie = () => Cookies.get(LEGACY_BASKET_COOKIE_KEY);

const expireLegacyCartCookie = (value) =>
  Cookies.set(LEGACY_BASKET_COOKIE_KEY, value, { domain: '.3.dk', expires: expires(-1) });

export {
  getOrderReference,
  setOrderReference,
  removeOrderReference,
  getCustomerTypeCookie,
  setCustomerTypeCookie,
  getBypassUnsupportedBrowser,
  setBypassUnsupportedBrowser,
  getAcceptedCustomerConsent,
  setAcceptedCustomerConsent,
  getLegacyCartCookie,
  expireLegacyCartCookie,
};
