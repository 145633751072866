import { gql } from '@apollo/client';
import cartFragment from '../fragments/cart';

const deleteCartMutation = gql`
  mutation deleteCart {
    deleteCart {
      ...Cart
    }
  }
  ${cartFragment}
`;

export default deleteCartMutation;
