import { gql } from '@apollo/client';

const getUpsalesQuery = gql`
  query getDevicePageUpsalesQuery {
    devicePageUpsales {
      status
      message
      upsales {
        id
        description
        imageURL
        price
        isPaidUpfront
        displayName
        splashText
      }
    }
  }
`;

export default getUpsalesQuery;
