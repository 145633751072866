import { useMutation, useQuery } from '@apollo/client';
import addProductToLegacyCartMutation from 'apollo/mutations/addProductToLegacyCart';
import removeBasketRowFromLegacyCartMutation from 'apollo/mutations/removeBasketRowFromLegacyCart';
import removeFromLegacyCartMutation from 'apollo/mutations/removeProductFromLegacyCart';
import legacyCartQuery from 'apollo/queries/legacyCartQuery';
import getUnifiedCartQuery from 'apollo/queries/getUnifiedCart';
import ApolloResponseStatus from '../../apollo/ApolloResponseStatus';
import removeBasketRowsFromLegacyCartMutation from '../../apollo/mutations/removeBasketRowsFromLegacyCart';
import deleteLegacyCartMutation from '../../apollo/mutations/deleteLegacyCart';
import { expireLegacyCartCookie } from '../cookieHelpers/cookies';
import updateAccountLevelDiscountMutation from '../../apollo/mutations/updateAccountLevelDiscount';

/**
 * Custom Cart hook to wrap all logic related to updating the legacy cart
 */
const useLegacyCart = ({ bypassCache } = {}) => {
  const {
    data: legacyCartData,
    loading: legacyCartLoading,
    error: legacyCartError,
    refetch: legacyCartRefetch,
  } = useQuery(legacyCartQuery, { ssr: false, fetchPolicy: bypassCache ? 'network-only' : 'cache-first' });

  const [addProductToLegacyCart, { loading: addProductToLegacyCartLoading, error: addProductToLegacyCartError }] =
    useMutation(addProductToLegacyCartMutation, {
      update: (cache, { data }) => {
        const { status, response } = data.addProductToLegacyCart;
        if (status === ApolloResponseStatus.SUCCESS) {
          cache.writeQuery({
            query: legacyCartQuery,
            data: { legacyCart: response },
          });
        }
      },
      refetchQueries: [getUnifiedCartQuery],
    });

  const [removeFromLegacyCart, { loading: removeFromLegacyCartLoading, error: removeFromLegacyCartError }] =
    useMutation(removeFromLegacyCartMutation, {
      update: (cache, { data }) => {
        const { status, response } = data.removeProductFromLegacyCart;
        if (status === ApolloResponseStatus.SUCCESS) {
          cache.writeQuery({
            query: legacyCartQuery,
            data: { legacyCart: response },
          });
        }
      },
      refetchQueries: [getUnifiedCartQuery],
    });

  const [
    removeBasketRowFromLegacyCart,
    { loading: removeBasketRowFromLegacyCartLoading, error: removeBasketRowFromLegacyCartError },
  ] = useMutation(removeBasketRowFromLegacyCartMutation, {
    update: (cache, { data }) => {
      const { status, response } = data.removeBasketRowFromLegacyCart;
      if (status === ApolloResponseStatus.SUCCESS) {
        cache.writeQuery({
          query: legacyCartQuery,
          data: { legacyCart: response },
        });
      }
    },
    refetchQueries: [getUnifiedCartQuery],
  });

  const [
    removeBasketRowsFromLegacyCart,
    { loading: removeBasketRowsFromLegacyCartLoading, error: removeBasketRowsFromLegacyCartError },
  ] = useMutation(removeBasketRowsFromLegacyCartMutation, {
    update: (cache, { data }) => {
      const { status, response } = data.removeBasketRowsFromLegacyCart;
      if (status === ApolloResponseStatus.SUCCESS) {
        cache.writeQuery({
          query: legacyCartQuery,
          data: { legacyCart: response },
        });
      }
    },
    refetchQueries: [getUnifiedCartQuery],
  });

  const [
    updateAccountLevelDiscount,
    { loading: updateAccountLevelDiscountLoading, error: updateAccountLevelDiscountError },
  ] = useMutation(updateAccountLevelDiscountMutation, {
    update: (cache, { data }) => {
      if (!updateAccountLevelDiscountError) {
        cache.writeQuery({
          query: legacyCartQuery,
          data: { legacyCart: data.updateAccountLevelDiscount },
        });
      }
    },
    refetchQueries: [getUnifiedCartQuery],
  });

  const [deleteLegacyCart] = useMutation(deleteLegacyCartMutation, {
    update: (cache) => {
      cache.evict({ fieldName: 'legacyCart' });
      cache.evict({ fieldName: 'unifiedCart' });
      cache.gc();
    },
  });

  const softDeleteLegacyCart = () => {
    expireLegacyCartCookie();
    legacyCartRefetch();
  };

  return {
    legacyCartData,
    legacyCartLoading,
    legacyCartError,
    legacyCartRefetch,
    addProductToLegacyCart,
    addProductToLegacyCartLoading,
    addProductToLegacyCartError,
    removeFromLegacyCart,
    removeFromLegacyCartLoading,
    removeFromLegacyCartError,
    removeBasketRowFromLegacyCart,
    removeBasketRowFromLegacyCartLoading,
    removeBasketRowFromLegacyCartError,
    removeBasketRowsFromLegacyCart,
    removeBasketRowsFromLegacyCartLoading,
    removeBasketRowsFromLegacyCartError,
    updateAccountLevelDiscount,
    updateAccountLevelDiscountLoading,
    updateAccountLevelDiscountError,
    deleteLegacyCart,
    softDeleteLegacyCart,
  };
};

export default useLegacyCart;
