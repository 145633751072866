import PropTypes from 'prop-types';
import ErrorPage404 from './errorPages/ErrorPage404';
import ErrorPage500 from './errorPages/ErrorPage500';

// Built-in apollo error codes
// https://www.apollographql.com/docs/apollo-server/data/errors/#error-codes
const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';

// Custom Apollo errors defined in 3api
const NOT_FOUND = 'NOT_FOUND';

const ApolloErrorHandler = ({ error }) => {
  const code = error.graphQLErrors[0]?.extensions.code;
  switch (String(code)) {
    case '500':
    case INTERNAL_SERVER_ERROR:
      return <ErrorPage500 error={error} />;
    case '404':
    case NOT_FOUND:
      return <ErrorPage404 />;
    // Return 500 for all other cases for now.
    // Should be changed once we know more about how
    // other error pages should look.
    default:
      return <ErrorPage500 error={error} />;
  }
};

ApolloErrorHandler.propTypes = {
  error: PropTypes.shape({
    graphQLErrors: PropTypes.arrayOf(
      PropTypes.shape({
        extensions: PropTypes.shape({
          code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      }),
    ),
  }).isRequired,
};

export default ApolloErrorHandler;
