import PropTypes from 'prop-types';

const assetPropType = PropTypes.shape({
  description: PropTypes.string,
  mediaType: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
});

export default assetPropType;
