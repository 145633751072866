import { error as logError } from '@hi3g-access/client-logger';
import useLegacyCart from './useLegacyCartHook';
import { triggerUpsaleBridge } from '../customEvents/BridgeUpsaleEvent';
import dataLayer from '../dataLayer/dataLayer';

const useOneClickAddToCart = () => {
  const { addProductToLegacyCart, addProductToLegacyCartError, addProductToLegacyCartLoading } = useLegacyCart();
  if (addProductToLegacyCartError) {
    logError(`Error when adding to cart using oneClickBasket: ${addProductToLegacyCartError}`);
  }

  const oneClickAddToCart = ({ oneClickProducts, customOnCompleted }) => {
    const mainProduct = oneClickProducts.find((product) => product.isMainProduct);
    const secondaryProducts = (oneClickProducts || [])
      .filter((product) => !product.isMainProduct)
      .flatMap((product) => product.product);

    addProductToLegacyCart({
      variables: {
        items: mainProduct.product,
        bundledProducts: secondaryProducts,
        upfront: 0,
      },
      onCompleted: (data) => {
        const { products } = data?.addProductToLegacyCart.response || {};
        const imageUrl = (products || [])[0]?.device?.image || '';
        dataLayer.oneClickBasketEvent(products, [mainProduct.product, ...secondaryProducts.map((p) => [p])]);
        triggerUpsaleBridge({
          url: imageUrl,
          alt: 'upsale bridge product image',
        });
        if (customOnCompleted) {
          customOnCompleted();
        }
      },
    });
  };

  return { oneClickAddToCart, oneClickAddToCartLoading: addProductToLegacyCartLoading };
};

export default useOneClickAddToCart;
