const LINK_TYPES = {
  URL: 'URL',
  ENTRY: 'Page',
  ASSET: 'Asset',
  ONE_CLICK: 'oneClickBasket',
  TRADE_IN: 'Trade In',
  ANCHOR: 'Anchor Link',
};

export default LINK_TYPES;
