import { useEffect } from 'react';

const TRADE_IN_PANEL = 'trade:in:panel';

export const triggerTradeInPanel = (data) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.dispatchEvent(new CustomEvent(TRADE_IN_PANEL, { detail: data }));
};

const useTradeInEvent = (handler, deps) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }
    window.addEventListener(TRADE_IN_PANEL, handler);
    return () => {
      window.removeEventListener(TRADE_IN_PANEL, handler);
    };
  }, deps);
};

export default useTradeInEvent;
