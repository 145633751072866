import { Container } from '@3dk/3style';
import PropTypes from 'prop-types';

const WidthWrapper = ({ useFullWidth, children, ...rest }) => (
  <Container maxWidth={useFullWidth ? false : undefined} {...rest}>
    {children}
  </Container>
);

WidthWrapper.propTypes = {
  useFullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disableGutters: PropTypes.bool,
};
// You can add container props as default props.
// See https://mui.com/material-ui/api/container/ for possible options.
WidthWrapper.defaultProps = {
  useFullWidth: false,
  disableGutters: false,
};
export default WidthWrapper;
