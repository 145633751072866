const runtimeConfig =
  typeof window !== 'undefined' && typeof window.environmentConfig !== 'undefined'
    ? {
        // client
        PORT: window.environmentConfig.PORT,
        RAZZLE_GRAPHQL_SERVER_ENDPOINT: window.environmentConfig.RAZZLE_GRAPHQL_SERVER_ENDPOINT,
        RAZZLE_GRAPHQL_CLIENT_ENDPOINT: window.environmentConfig.RAZZLE_GRAPHQL_CLIENT_ENDPOINT,
        RAZZLE_REST_ENDPOINT: window.environmentConfig.RAZZLE_REST_ENDPOINT,
        RAZZLE_SET_STATIC_PATH_AT_COMPILE_TIME: window.environmentConfig.RAZZLE_SET_STATIC_PATH_AT_COMPILE_TIME,
        CLIENT_LOGGER_ENDPOINT: window.environmentConfig.CLIENT_LOGGER_ENDPOINT,
        CLIENT_LOGGER_SERVER_ENDPOINT: window.environmentConfig.CLIENT_LOGGER_SERVER_ENDPOINT,
        AGENT_LOGIN_URI: window.environmentConfig.AGENT_LOGIN_URI,
        MENU_BASE_URI: window.environmentConfig.MENU_BASE_URI,
        FORMSTACK_IFRAME_SRC: window.environmentConfig.FORMSTACK_IFRAME_SRC,
        NODE_ENV: window.environmentConfig.NODE_ENV,
        DD_ENV: window.environmentConfig.DD_ENV,
        WEB_SHOP_ORIGIN_URL: window.environmentConfig.WEB_SHOP_ORIGIN_URL,
        CORS_DOMAINS: window.environmentConfig.CORS_DOMAINS,
        AUTH_LOGIN_URL: window.environmentConfig.AUTH_LOGIN_URL,
        IS_ESIM_DEFAULT: window.environmentConfig.IS_ESIM_DEFAULT,
        OPTIMIZELY_SDK_KEY: window.environmentConfig.OPTIMIZELY_SDK_KEY,
        BASE_URI: window.environmentConfig.BASE_URI,
        CWA_REDIRECT_BASE_URL: window.environmentConfig.CWA_REDIRECT_BASE_URL,
        GOOGLE_RECAPTCHA_SITE_KEY: window.environmentConfig.GOOGLE_RECAPTCHA_SITE_KEY,
      }
    : {
        // server
        PORT: process.env.PORT,
        RAZZLE_GRAPHQL_SERVER_ENDPOINT: process.env.RAZZLE_GRAPHQL_SERVER_ENDPOINT,
        RAZZLE_GRAPHQL_CLIENT_ENDPOINT: process.env.RAZZLE_GRAPHQL_CLIENT_ENDPOINT,
        RAZZLE_REST_ENDPOINT: process.env.RAZZLE_REST_SERVER_ENDPOINT || process.env.RAZZLE_REST_ENDPOINT,
        RAZZLE_SET_STATIC_PATH_AT_COMPILE_TIME: process.env.RAZZLE_SET_STATIC_PATH_AT_COMPILE_TIME,
        CLIENT_LOGGER_ENDPOINT: process.env.CLIENT_LOGGER_ENDPOINT,
        CLIENT_LOGGER_SERVER_ENDPOINT: process.env.CLIENT_LOGGER_SERVER_ENDPOINT,
        AGENT_LOGIN_URI: process.env.AGENT_LOGIN_URI,
        MENU_BASE_URI: process.env.MENU_BASE_URI,
        FORMSTACK_IFRAME_SRC: process.env.FORMSTACK_IFRAME_SRC,
        NODE_ENV: process.env.NODE_ENV,
        DD_ENV: process.env.DD_ENV,
        WEB_SHOP_ORIGIN_URL: process.env.WEB_SHOP_ORIGIN_URL,
        CORS_DOMAINS: process.env.CORS_DOMAINS,
        AUTH_LOGIN_URL: process.env.AUTH_LOGIN_URL,
        IS_ESIM_DEFAULT: process.env.IS_ESIM_DEFAULT,
        OPTIMIZELY_SDK_KEY: process.env.OPTIMIZELY_SDK_KEY,
        BASE_URI: process.env.BASE_URI,
        CWA_REDIRECT_BASE_URL: process.env.CWA_REDIRECT_BASE_URL,
        GOOGLE_RECAPTCHA_SITE_KEY: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
      };

/**
 * Not all keys are required for clients
 * And sometimes you need to change value based on client or server
 * cleanup env values here
 */
export const getRuntimeConfigForClient = () => {
  runtimeConfig.RAZZLE_REST_ENDPOINT = process.env.RAZZLE_REST_ENDPOINT;
  delete runtimeConfig.RAZZLE_GRAPHQL_SERVER_ENDPOINT;
  return runtimeConfig;
};

export default runtimeConfig;
