import { ACCESSORY_BASE, CATEGORY_BASE, SUBSCRIPTION_PRODUCT_PAGE_BASE } from 'constants/urls';
import PAGE_TYPE from 'constants/pageTypes';

const getBaseUrl = (pageType) => {
  const lowerCasePageType = pageType?.toLowerCase();

  switch (lowerCasePageType) {
    case PAGE_TYPE.PAGE_CONTENT_TYPE:
      return ACCESSORY_BASE;
    case PAGE_TYPE.PAGE_CATEGORY_TYPE:
      return CATEGORY_BASE;
    case PAGE_TYPE.SUBSCRIPTION_PAGE:
      return SUBSCRIPTION_PRODUCT_PAGE_BASE;
    case PAGE_TYPE.DEVICE_LIST_PAGE:
    case PAGE_TYPE.EXTERNAL_PAGE:
    case PAGE_TYPE.CONTENT_PAGE:
      return '/';
    default:
      // This rule is applied only to the 'Page' content model from the Business space.
      return '';
  }
};

export default getBaseUrl;
