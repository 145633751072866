import { gql } from '@apollo/client';

const syncSessionQuery = gql`
  query syncSession {
    syncSession {
      response
    }
  }
`;

export default syncSessionQuery;
