import PropTypes from 'prop-types';
import { Banner, Body, Button, Container, Title, PRIMARY_WHITE, PRIMARY_ORANGE, Grid } from '@3dk/3style';
import browserConfiguration from './invalidBrowser/validBrowserConfiguration';
import LinkButton from '../components/LinkButton';

const classes = {
  contentWrapper: {
    marginTop: '3em',
  },
  title: {
    marginTop: '1em',
  },
  bodyText: {
    marginTop: '1em',
  },
  linkStyle: (theme) => ({
    color: theme.palette.PRIMARY_ORANGE,
    textDecoration: 'none',
  }),
  buttonWrapper: {
    width: 'inherit',
    marginTop: '1em',
  },
  unstyleATag: (theme) => ({
    color: theme.palette.PRIMARY_WHITE,
    textDecoration: 'none',
  }),
};

const InvalidBrowser = ({ browserName, onClickContinue }) => {
  const { updateBrowserLinks } = browserConfiguration;
  const browserItem = updateBrowserLinks.find((item) => item.browser === browserName);

  let browserLinks = [];
  if (browserItem && browserItem.otherBrowsers) {
    browserLinks = browserItem.otherBrowsers.map((item) => (
      <a href={item.link} css={classes.linkStyle}>
        {item.name}
      </a>
    ));
  }

  return (
    <Container maxWidth="lg" css={classes.contentWrapper}>
      <Banner>Din version af {browserName} er forældet</Banner>
      <Title css={classes.title}>Vi anbefaler at du opdaterer til nyeste version eller benytter en anden browser</Title>
      {browserItem && browserItem.errorMessage && <Body css={classes.bodyText}>{browserItem.errorMessage}</Body>}
      {browserItem && browserItem.otherBrowsers && browserLinks && (
        <Body css={classes.bodyText}>
          {/* &nbsp; is a non-breaking space character in html */}
          Du kan også vælge at benytte en anden browser i stedet for&nbsp;
          {browserName}, fx.&nbsp;
          {browserLinks.map((link, index) => {
            if (index === 0) return <span key={`${link}-${index}`}>{link}</span>;
            if (index === browserLinks.length - 1) {
              return <span key={`${link}-${index}`}> eller {link}</span>;
            }
            return <span key={`${link}-${index}`}>, {link}</span>;
          })}
        </Body>
      )}
      <Body css={classes.bodyText}>
        Det betyder også, at du på 3.dk ikke længere vil få den rigtige oplevelse af vores inhold. Siden kan se mærkelig
        ud og i værste tilfælde kan dele ikke virke for dig - fx. gennemføre køb.
      </Body>
      <Grid container css={classes.buttonWrapper}>
        {browserItem && browserItem.updateLink && (
          <Grid xs={12}>
            <Button color="secondary">
              {/* External link - hence the anchor tag */}
              <a href={browserItem.updateLink} css={classes.unstyleATag}>
                Opdater {browserName}
              </a>
            </Button>
          </Grid>
        )}
        <Grid xs={12}>
          <LinkButton variant="outlined" href="/" onClick={onClickContinue}>
            Ok, jeg forstår. Besøg 3.dk alligevel
          </LinkButton>
        </Grid>
      </Grid>
    </Container>
  );
};

InvalidBrowser.propTypes = {
  browserName: PropTypes.string.isRequired,
  onClickContinue: PropTypes.func.isRequired,
};

export default InvalidBrowser;
