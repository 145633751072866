import { gql } from '@apollo/client';

export const cartPriceDetailsFragment = gql`
  fragment cartPriceDetailsFragment on PriceDetails {
    inclVat
    vatAmount
  }
`;

export const summarizedOfferingPricesFragment = gql`
  fragment summarizedOfferingPricesFragment on SummarizedOfferingPrices {
    totalUpfrontCost {
      ...cartPriceDetailsFragment
    }
    totalInstallmentCost {
      ...cartPriceDetailsFragment
    }
    monthlyInstallmentTermCosts {
      term
      amount
    }
    monthlyRecurringCost {
      ...cartPriceDetailsFragment
    }
    monthlyRecurringTermCosts {
      term
      amount
    }
    minimumCost {
      ...cartPriceDetailsFragment
    }
  }
  ${cartPriceDetailsFragment}
`;

export const cartItemPriceFragment = gql`
  fragment cartItemPriceFragment on CartItemPrice {
    vatPercentage
    sellingPrice
    reductionAmount
    originalPrice
    recurringPrice
    discount {
      discountedPrice
      type
      reductionAmount
      reductionPercentage
      duration
    }
  }
`;

export const cartItemProductFragment = gql`
  fragment cartItemProductFragment on CartItemProduct {
    id
    type
    name
    primaryProduct {
      id
      type
      name
      contentMedia {
        type
        url
        title
        description
      }
    }
    includedProducts {
      id
      model {
        name
      }
    }
    contentMedia {
      type
      url
      title
      description
    }
  }
`;

export const cartItemOfferingFragment = gql`
  fragment cartItemOfferingFragment on CartItemOffering {
    id
    price {
      ...cartItemPriceFragment
    }
    product {
      ...cartItemProductFragment
    }
  }
  ${cartItemPriceFragment}
  ${cartItemProductFragment}
`;

export const cartItemsFragment = gql`
  fragment cartItemsFragment on CartItem {
    id
    chosenPaymentPlan {
      monthlyAmount
      originalMonthlyAmount
      upfrontAmount
      bindingPeriod
      noticePeriod
      installmentDuration
      planType
    }
    offering {
      ...cartItemOfferingFragment
    }
  }
  ${cartItemOfferingFragment}
`;

const cartFragment = gql`
  fragment Cart on Cart {
    id
    items {
      ...cartItemsFragment
    }
    summarizedOfferingPrices {
      ...summarizedOfferingPricesFragment
    }
  }
  ${summarizedOfferingPricesFragment}
  ${cartItemsFragment}
`;

// Creating special fragment for receipt to avoid cache interference with the regular cart.
export const receiptCartFragment = gql`
  fragment ReceiptCart on ReceiptCart {
    id
    items {
      ...cartItemsFragment
    }
    summarizedOfferingPrices {
      ...summarizedOfferingPricesFragment
    }
  }
  ${summarizedOfferingPricesFragment}
  ${cartItemsFragment}
`;

export default cartFragment;
