import React from 'react';
import { useLocation, Redirect } from 'react-router-dom';

const RedirectWithTrailingSlash = () => {
  const location = useLocation();
  const path = location.pathname;

  // If the last character of the url is not '/'
  if (path?.charAt(path.length - 1) !== '/') {
    return (
      <Redirect
        replace
        to={{
          pathname: path?.concat('/'),
          search: location.search,
          hash: location.hash,
        }}
      />
    );
  }
  return null;
};

export default RedirectWithTrailingSlash;
