const PAGE_TYPE = {
  PAGE: 'page',
  PAGE_CONTENT_TYPE: 'content',
  PAGE_CATEGORY_TYPE: 'category',
  CONTENT_PAGE: 'contentpage',
  EXTERNAL_PAGE: 'externalpage',
  DEVICE_LIST_PAGE: 'devicelistpage',
  SUBSCRIPTION_PAGE: 'subscriptionpage',
  RECEIPT_PAGE: 'receiptpage',
};
export default PAGE_TYPE;
