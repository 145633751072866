import PropTypes from 'prop-types';
import { LoadingButton } from '@3dk/3style';
import useOneClickAddToCart from '../utils/hooks/useOneClickAddToCart';

const makeClasses = (props) => ({
  button: {
    cursor: 'pointer',
    '&.MuiButton-root': {
      color: props.fontColor,
      background: props.buttonColor,
    },
  },
});

const OneClickBasket = (props) => {
  const { children, buttonColor, fontColor, oneClickProducts, className } = props;
  const { oneClickAddToCart, oneClickAddToCartLoading } = useOneClickAddToCart();

  const classes = makeClasses({ buttonColor, fontColor });

  const hasOneClickProducts = oneClickProducts?.length > 0;

  return (
    <LoadingButton
      pending={oneClickAddToCartLoading}
      onClick={hasOneClickProducts ? () => oneClickAddToCart({ oneClickProducts }) : undefined}
      css={classes.button}
      className={className}
    >
      {children}
    </LoadingButton>
  );
};

OneClickBasket.propTypes = {
  children: PropTypes.node.isRequired,
  buttonColor: PropTypes.string,
  fontColor: PropTypes.string,
  oneClickProducts: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.arrayOf(PropTypes.string),
      isMainProduct: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
};

OneClickBasket.defaultProps = {
  oneClickProducts: [],
  buttonColor: '',
  fontColor: '',
  className: '',
};

export default OneClickBasket;
