import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import addTrailingSlash from 'utils/url/addTrailingSlash';
import { triggerTradeInPanel } from '../utils/customEvents/TradeInEvent';
import OneClickBasket from './OneClickBasket';
import getBaseUrl from '../utils/url/getBaseUrl';
import linkPropType, { LinkDefaultValues } from '../utils/propTypes/link';
import LINK_TYPES from '../constants/linkTypes';

export const getLink = (linkType, internalLink, url, actionProps) => {
  let Element;

  const elementProps = { className: actionProps.className };
  switch (linkType) {
    case LINK_TYPES.ENTRY:
      Element = Link;
      elementProps.to = `${getBaseUrl(internalLink?.pageType || internalLink?.type)}${addTrailingSlash(url)}`;
      break;
    case LINK_TYPES.URL:
    case LINK_TYPES.ASSET:
      Element = 'a';
      elementProps.href = url;
      break;
    case LINK_TYPES.ANCHOR:
      // If there is given a url, the anchor link is to content on another page, or else it is to content on the current page
      if (url) {
        // Use <a> here to avoid client side rendering causing element not to be rendered when trying to scroll it into view
        Element = 'a';
        const anchorPath = `${getBaseUrl(internalLink?.pageType || internalLink?.type)}${addTrailingSlash(url)}`;
        const anchorQuery = actionProps?.location.search || '';
        elementProps.href = `${anchorPath}${anchorQuery}#${actionProps.anchorLink}`;
      } else {
        // If we are on the same page as the anchor link we can use react router
        Element = Link;
        elementProps.to = {
          pathname: actionProps?.location.pathname,
          search: actionProps?.location.search,
          hash: actionProps.anchorLink,
        };
      }
      break;
    case LINK_TYPES.TRADE_IN:
      Element = 'div';
      elementProps.onClick = () => triggerTradeInPanel({ isOpen: true, paymentPeriod: 10 });
      break;
    case LINK_TYPES.ONE_CLICK:
      Element = OneClickBasket;
      elementProps.buttonColor = actionProps.buttonColor;
      elementProps.fontColor = actionProps.fontColor;
      elementProps.oneClickProducts = actionProps.oneClickProducts;
      break;
    default:
      break;
  }
  return Element ? { Element, elementProps } : null;
};

const LinkResolver = ({
  buttonColor,
  fontColor,
  linkType,
  url,
  internalLink,
  children,
  className,
  oneClickProducts,
  anchorLink,
}) => {
  const location = useLocation();
  const resolvedLink = getLink(linkType, internalLink, url, {
    buttonColor,
    fontColor,
    oneClickProducts,
    className,
    anchorLink,
    location,
  });

  const { Element, elementProps } = resolvedLink || {};

  if (!Element) {
    return <>{children}</>;
  }

  return <Element {...(elementProps || {})}>{children}</Element>;
};

LinkResolver.propTypes = {
  ...linkPropType,
  children: PropTypes.node.isRequired,
};

LinkResolver.defaultProps = LinkDefaultValues;

export default LinkResolver;
