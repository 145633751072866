import { gql } from '@apollo/client';
import cartFragment from '../fragments/cart';

const getCartQuery = gql`
  query getCart {
    cart {
      ...Cart
    }
  }
  ${cartFragment}
`;

export default getCartQuery;
