import { gql } from '@apollo/client';
import legacyCartFragment from '../fragments/legacyCart';

const removeBasketRowsFromLegacyCartMutation = gql`
  mutation removeBasketRowsFromLegacyCart($basketRowIds: [String!]!) {
    removeBasketRowsFromLegacyCart(basketRowIds: $basketRowIds) {
      status
      message
      response {
        ...legacyCartFragment
      }
    }
  }
  ${legacyCartFragment}
`;

export default removeBasketRowsFromLegacyCartMutation;
