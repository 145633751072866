import { createContext, useContext, useMemo } from 'react';
import * as React from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

// TODO(NOV-2518): Move to separate package.
const FeatureFlagContext = createContext({});

export const FeatureFlagContextProvider = ({
  children,
  client,
  disableFeatureFlags,
  enabledFeaturesForAnonymousUser,
}) => {
  const userId = Cookies.get('feat_id') || 'UNKNOWN_USER';

  const getFeatureFlag = (feature, defaultValue) => {
    if (disableFeatureFlags) {
      return defaultValue ?? false;
    }

    if (!client) {
      return defaultValue;
    }

    const currentDatafile = client.projectConfigManager?.datafileManager?.currentDatafile;
    // datafile is there if fetch was successful
    if (currentDatafile) {
      return client.isFeatureEnabled(feature, userId, { userId });
    }
    return defaultValue;
  };

  const state = useMemo(
    () => ({
      disableFeatureFlags,
      enabledFeaturesForAnonymousUser,
      userId,
      getFeatureFlag,
    }),
    [disableFeatureFlags, enabledFeaturesForAnonymousUser, userId, getFeatureFlag],
  );

  return <FeatureFlagContext.Provider value={state}>{children}</FeatureFlagContext.Provider>;
};

FeatureFlagContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  client: PropTypes.any.isRequired,
  disableFeatureFlags: PropTypes.bool,
  enabledFeaturesForAnonymousUser: PropTypes.arrayOf(PropTypes.string),
};

FeatureFlagContextProvider.defaultProps = {
  disableFeatureFlags: false,
  enabledFeaturesForAnonymousUser: [],
};

export const useFeatureFlagContext = () => useContext(FeatureFlagContext);
