import { mapOfferingToAccessory } from '../mappers/accessoryMapper';

export const getDeletedOffer = (offeringId, items) => {
  const deletedItem = items.find((item) => item.id === offeringId);
  return mapOfferingToAccessory(deletedItem.offering);
};

export const getCartPurchaseActionField = (cart, orderId) => {
  const { totalUpfrontCost, totalInstallmentCost } = cart?.summarizedOfferingPrices || {};
  const revenue = (totalUpfrontCost?.inclVat ?? 0) + (totalInstallmentCost?.inclVat ?? 0);
  const tax = (totalUpfrontCost?.vatAmount ?? 0) + (totalInstallmentCost?.vatAmount ?? 0);
  // TODO: change when shipping is not free
  const shipping = 0;

  return {
    // Purchase with installment only does not have payment id
    ...(orderId && { id: orderId }),
    revenue,
    tax,
    shipping,
  };
};

export const generateProductsInCart = (cart) =>
  (cart?.items || []).map((item) => mapOfferingToAccessory(item.offering));
