import { useMutation, useQuery } from '@apollo/client';
import removeCartFromSessionMutation from '../../apollo/mutations/removeCartFromSession';
import getCartQuery from '../../apollo/queries/getCart';
import addToCartMutation from '../../apollo/mutations/addToCart';
import deleteCartMutation from '../../apollo/mutations/deleteCart';
import removeFromCartMutation from '../../apollo/mutations/removeFromCart';
import getDeliveryOptionsQuery from '../../apollo/queries/getDeliveryOptions';
import getOrderInfoQuery from '../../apollo/queries/getOrderInfo';
import getUnifiedCartQuery from '../../apollo/queries/getUnifiedCart';

/**
 * Custom Cart hook to wrap all logic related to updating the cart
 */

const useCart = (addToCartOnCompleted = () => null) => {
  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(getCartQuery, { ssr: false });

  const [addToCart, { loading: addToCartLoading, error: addToCartError }] = useMutation(addToCartMutation, {
    onCompleted() {
      addToCartOnCompleted();
    },
    update: (cache, { data }) => {
      cache.writeQuery({
        query: getCartQuery,
        data: { cart: data.addToCart },
      });
    },
    refetchQueries: [getUnifiedCartQuery],
  });

  const [removeFromCart, { loading: removeFromCartLoading, error: removeFromCartError }] = useMutation(
    removeFromCartMutation,
    {
      update: (cache, { data }) => {
        cache.writeQuery({
          query: getCartQuery,
          data: { cart: data.removeFromCart },
        });
      },
      refetchQueries: [getDeliveryOptionsQuery, getOrderInfoQuery, getUnifiedCartQuery],
    },
  );

  const [softDeleteCart] = useMutation(removeCartFromSessionMutation, {
    update(cache) {
      cache.evict({ fieldName: 'cart' });
      cache.gc();
    },
    refetchQueries: [getUnifiedCartQuery],
  });

  const [deleteCart] = useMutation(deleteCartMutation, {
    update(cache) {
      cache.evict({ fieldName: 'cart' });
      cache.gc();
    },
    refetchQueries: [getUnifiedCartQuery],
  });

  return {
    cartData,
    cartLoading,
    cartError,
    addToCart,
    addToCartLoading,
    addToCartError,
    removeFromCart,
    removeFromCartLoading,
    removeFromCartError,
    deleteCart,
    softDeleteCart,
  };
};

export default useCart;
