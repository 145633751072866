import { useEffect } from 'react';

const BRIDGE_UPSALE = 'bridge:upsale';
export const triggerUpsaleBridge = (bridgeImage) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.dispatchEvent(new CustomEvent(BRIDGE_UPSALE, { detail: { bridgeImage } }));
};

const useBridgeUpsaleEvent = (handler, deps) => {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }
    window.addEventListener(BRIDGE_UPSALE, handler);
    return () => {
      window.removeEventListener(BRIDGE_UPSALE, handler);
    };
  }, deps);
};

export default useBridgeUpsaleEvent;
