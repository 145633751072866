import { gql } from '@apollo/client';
import cartFragment from '../fragments/cart';

const removeFromCartMutation = gql`
  mutation removeFromCart($cartOfferingId: String!) {
    removeFromCart(cartOfferingId: $cartOfferingId) {
      ...Cart
    }
  }
  ${cartFragment}
`;

export default removeFromCartMutation;
