import { gql } from '@apollo/client';

const getOrderInfoQuery = gql`
  query getOrderInfo {
    getOrderInfo {
      id
      emailAddress
      phoneNumber
      deliveryDetails {
        firstName
        lastName
      }
    }
  }
`;

export default getOrderInfoQuery;
