import { DEVICE_TYPE } from '../../../constants/urls';
/**
 * Those methods should only be used when mapping devices to datalayer
 *
 * devices should have the same structure and almost the same data,
 * it's case-sensitive
 */

const getVariantString = (vendorColorName, size, subscription, paymentPlan) =>
  `${vendorColorName ? `colour: ${vendorColorName}` : ''}${size ? ` | size: ${size}` : ''}${
    subscription ? ` | subscription: ${subscription}` : ''
  }${paymentPlan ? ` | payment: ${paymentPlan}` : ''}`;

const getLegacyDeviceId = (brand, name, size, color) => [brand, name, size, color].filter(Boolean).join(' ');

export const mapDeviceToDataLayer = (device) => {
  const { size, vendorColorName, brand, price, paymentPlan, subscription, name, deviceId, pageType } = device;
  return {
    brand,
    category: DEVICE_TYPE[pageType],
    id: getLegacyDeviceId(brand, name, size, vendorColorName),
    name: getLegacyDeviceId(brand, name),
    price,
    variant: getVariantString(vendorColorName, size, subscription.label, paymentPlan),
    webId: deviceId,
  };
};

export const deviceListItemToDataLayer = (product, position, list) => {
  const [variant] = product.variants;
  const paymentPlan = `${variant.offering?.paymentPlanOptions.installmentPlans[0]?.duration}md`;
  return {
    brand: product.brand,
    category: DEVICE_TYPE[product.type],
    id: getLegacyDeviceId(product.brand, product.name, variant.size, variant.color),
    name: getLegacyDeviceId(product.brand, product.name),
    price: variant.offering?.price.sellingPrice,
    variant: getVariantString(variant.color, variant.size, null, paymentPlan),
    webId: product.id,
    // extra fields
    position,
    list,
  };
};

export const generateDeviceInLegacyCart = (device, subscription) => {
  if (!device) return null;

  const { brand, type, name, size, color, id, price } = device;
  const isInstallment = price?.discountedRecurringPrice > 0;
  const devicePayment = isInstallment ? `${price.bindingPeriod} md` : 'Pay now';

  return {
    brand,
    category: DEVICE_TYPE[type],
    id: getLegacyDeviceId(brand, name, size, color),
    name: getLegacyDeviceId(brand, name),
    price: price.totalDiscountedPrice,
    variant: getVariantString(color, size, subscription?.name, devicePayment),
    webId: id,
    // extra fields
    quantity: 1,
  };
};

export const getAdditionalInfo = (device) => {
  const {
    size,
    vendorColorName,
    brand,
    paymentPlan,
    upfrontPrice,
    subscription,
    subscriptionMonthlyPrice,
    name,
    pageType,
  } = device;
  return {
    colourSelected: vendorColorName,
    depositRequired: upfrontPrice || upfrontPrice === 0 ? upfrontPrice.toString() : '',
    paymentPlanSelected: paymentPlan,
    planPricePerMonth:
      subscriptionMonthlyPrice || subscriptionMonthlyPrice === 0 ? subscriptionMonthlyPrice.toString() : '',
    productMake: brand,
    productModel: `${size} ${vendorColorName}`,
    productName: name,
    productPageType: pageType,
    productPlanSelected: subscription.label,
  };
};
