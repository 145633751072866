import { BridgeBody, BridgeUpsale, SimCardIcon } from '@3dk/3style';
import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import ApolloResponseStatus from '../apollo/ApolloResponseStatus';
import getUpsalesQuery from '../apollo/queries/getDevicePageUpsalesQuery';
import useLegacyCart from '../utils/hooks/useLegacyCartHook';
import useBridgeUpsaleEvent from '../utils/customEvents/BridgeUpsaleEvent';
import showUnifiedCartEvent from '../utils/customEvents/showUnifiedCartEvent';
import { PRE_DC_CHECKOUT } from '../constants/urls';
import dataLayer from '../utils/dataLayer/dataLayer';

const BridgeUpsaleContainer = () => {
  const [isBridgeOpen, setIsBridgeOpen] = useState(false);
  const [bridgeImage, setBridgeImage] = useState(null);
  const [selectedUpsaleItemIds, setSelectedUpsaleItemIds] = useState([]);
  const { legacyCartData, addProductToLegacyCart, removeFromLegacyCart } = useLegacyCart();
  const [getUpsales, { data: upsalesData }] = useLazyQuery(getUpsalesQuery, {
    fetchPolicy: 'no-cache',
  });

  useBridgeUpsaleEvent(async (event) => {
    setBridgeImage(event.detail?.bridgeImage);
    getUpsales().then(() => {
      setSelectedUpsaleItemIds([]);
      setIsBridgeOpen(true);
    });
  }, []);

  const handleShowLegacyCart = () => {
    setIsBridgeOpen(false);
    showUnifiedCartEvent();
  };
  const handleUpsale = (upsaleProduct, isSelected) => {
    const { id, price } = upsaleProduct;
    // assumption: we are only adding or removing upsale from last product
    const lastProduct = [...(legacyCartData?.legacyCart?.products || [])].pop();
    if (isSelected) {
      removeFromLegacyCart({ variables: { productId: id, basketRowId: lastProduct?.id } }).then(() =>
        dataLayer.removeUpsaleFromCart(lastProduct, id),
      );
      setSelectedUpsaleItemIds((prevState) => prevState.filter((i) => i !== id));
    } else {
      addProductToLegacyCart({ variables: { items: [id], upfront: price, replaceBasket: false } }).then((basket) =>
        dataLayer.addUpsaleToCart(basket, lastProduct?.id, id),
      );
      setSelectedUpsaleItemIds((prevState) => prevState.concat(id));
    }
  };

  const upsales =
    upsalesData?.devicePageUpsales.status === ApolloResponseStatus.SUCCESS
      ? upsalesData.devicePageUpsales?.upsales.map(
          ({ id, description, imageURL, price, isPaidUpfront, displayName, splashText }) => ({
            id,
            body: description,
            image: { src: imageURL },
            price,
            isPaidUpfront,
            header: displayName,
            splashText,
          }),
        )
      : [];

  return (
    <BridgeUpsale
      hasUpsale={upsales.length > 0}
      image={{ src: bridgeImage?.url ? decodeURIComponent(bridgeImage?.url) : undefined, alt: bridgeImage?.alt }}
      checkoutUrl={PRE_DC_CHECKOUT}
      isOpen={isBridgeOpen}
      icon={<SimCardIcon />}
      setIsOpen={setIsBridgeOpen}
      showCart={handleShowLegacyCart}
    >
      {upsales.length > 0 && (
        <BridgeBody items={upsales} onItemClick={handleUpsale} selectedItemIds={selectedUpsaleItemIds} />
      )}
    </BridgeUpsale>
  );
};

export default BridgeUpsaleContainer;
