import { gql } from '@apollo/client';
import cartFragment from '../fragments/cart';

const addToCartMutation = gql`
  mutation addToCart($offering: CartOfferingInput!) {
    addToCart(offering: $offering) {
      ...Cart
    }
  }
  ${cartFragment}
`;

export default addToCartMutation;
