import optimizelySDK from '@optimizely/optimizely-sdk';
import runtimeConfig from '../../utils/config';

let optimizelyClient;

const loadOptimizelyClient = ({ sdkKey, dataFile }) => {
  optimizelySDK.setLogLevel('warn');
  optimizelySDK.setLogger(optimizelySDK.logging.createLogger());
  const SIXTY_SECONDS = 60000;

  const datafileOptions = {
    autoUpdate: true,
    updateInterval: SIXTY_SECONDS,
    urlTemplate: new URL(`${runtimeConfig.RAZZLE_REST_ENDPOINT}/optimizely/datafiles/%s.json`).href,
  };

  return optimizelySDK.createInstance({
    datafileOptions,
    eventBatchSize: 10, // recommended by optimizely
    eventFlushInterval: 1000, // recommended by optimizely
    sdkKey,
    datafile: dataFile,
  });
};

export const getOptimizelyClient = ({ sdkKey, dataFile }) => {
  if (!optimizelyClient) {
    optimizelyClient = loadOptimizelyClient({
      dataFile,
      sdkKey,
    });
  }
  return optimizelyClient;
};

export const getReadyOptimizelyClient = async (options) => {
  const FIVE_SECONDS = 5000;
  const client = getOptimizelyClient(options);
  await client.onReady({ timeout: FIVE_SECONDS });
  return client;
};
