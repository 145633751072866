import addTrailingSlash from 'utils/url/addTrailingSlash';

export const ACCESSORY_BASE = '/tilbehoer/';
export const CATEGORY_BASE = `/shop${ACCESSORY_BASE}`;
export const CATEGORY_PAGE = `${CATEGORY_BASE}:category/`;
export const PRODUCT_PAGE = `${CATEGORY_PAGE}:id/`;
export const SUBSCRIPTION_BASE = '/abonnementer/';
export const SUBSCRIPTION_DETAILS = `${SUBSCRIPTION_BASE}:id/`;
export const CART_RECOVERY = `${ACCESSORY_BASE}cart-recovery/:cartId/`;
export const CHECKOUT = `${ACCESSORY_BASE}checkout/`;
export const RECEIPT = '/kvittering/';
export const THANK_YOU = `${RECEIPT}:orderReference/`;
export const NEM_ID_VERIFICATION = `${ACCESSORY_BASE}verification/`;
export const ORDER_VERIFICATION = `${ACCESSORY_BASE}orderverification/`;
export const NEM_ID_FALLBACK = `${ACCESSORY_BASE}checkout/nemid-fejl/`;
export const NEM_ID_FALLBACK_VERIFICATION = `${ACCESSORY_BASE}checkout/nemid-fejl/verification/`;
export const NEM_ID_FALLBACK_RECEIPT = `${ACCESSORY_BASE}checkout/nemid-fejl/kvittering/`;
export const CONFIRMATION_SELECTION = `/mine-tilbud/`;
export const CUSTOMER_CONFIRM = `${CONFIRMATION_SELECTION}:orderReference/`;
export const MY3_BASE = '/selvbetjening/oversigt/';

// Agent routes
export const AGENT_BASE = '/agent';
export const AGENT_START_PAGE = `${AGENT_BASE}${CATEGORY_BASE}`;
export const AGENT_CATEGORY_PAGE = `${AGENT_BASE}${CATEGORY_PAGE}`;
export const AGENT_PRODUCT_SEARCH = `${AGENT_BASE}${CATEGORY_BASE}produkt/`;
export const AGENT_PRODUCT_PAGE = `${AGENT_BASE}${PRODUCT_PAGE}`;
export const AGENT_SUBSCRIPTION_PAGE = `${AGENT_BASE}${SUBSCRIPTION_DETAILS}`;
export const AGENT_CHECKOUT = `${AGENT_BASE}${CHECKOUT}`;
export const AGENT_CONFIRMATION_SELECTION = `${AGENT_BASE}${CONFIRMATION_SELECTION}`;
export const VIEW_OFFER = `${AGENT_BASE}${CUSTOMER_CONFIRM}`;

// Deprecated pages
// TODO: These are the former url structures, from which we redirect to the new urls.
// This is only temporary and will be removed by (NOV-2577)
const DEPRECATED_URL_STRUCTURE = '/tilbehoer/kategori/';
export const DEPRECATED_CATEGORY_PAGE = `${DEPRECATED_URL_STRUCTURE}:category/`;
export const DEPRECATED_PRODUCT_PAGE = `${DEPRECATED_CATEGORY_PAGE}:id/`;
export const DEPRECATED_AGENT_START_PAGE = `${AGENT_BASE}${DEPRECATED_URL_STRUCTURE}`;
export const DEPRECATED_AGENT_CATEGORY_PAGE = `${AGENT_BASE}${DEPRECATED_CATEGORY_PAGE}`;
export const DEPRECATED_AGENT_PRODUCT_PAGE = `${AGENT_BASE}${DEPRECATED_PRODUCT_PAGE}`;

// Pre-DC routes
export const SHOP_BASE = '/shop/';
export const DEVICE_PRODUCT_LIST_PAGE = `${SHOP_BASE}:type/`;
export const DEVICE_BRAND_LIST_PAGE = `${DEVICE_PRODUCT_LIST_PAGE}:brand/`;
export const DEVICE_PRODUCT_PAGE = `${DEVICE_BRAND_LIST_PAGE}:device/`;
export const PRE_DC_CHECKOUT = `${SHOP_BASE}checkout/`;
export const PRE_DC_ORDER_VERIFICATION = `${SHOP_BASE}order-verification/`;
export const PRE_DC_RECEIPT = `${SHOP_BASE}kvittering/:orderId/`;
export const PRE_DC_CREDIT_CHECK_REJECTED_PAGE = `${PRE_DC_CHECKOUT}afvist/`;
export const PRE_DC_CONTACT_CUSTOMER_SERVICE = `${PRE_DC_CHECKOUT}kontrol/`;
export const ABANDON_CHECKOUT = `${PRE_DC_CHECKOUT}afbryd/`;

// HelpPages
export const HELP_PAGE_BASE = '/hjalp';
export const NEW_HELP_PAGE_BASE = '/new-hjalp'; // Temporarily added for preview to work.
export const HELP_PAGE_SHOPS = `${HELP_PAGE_BASE}/shops`;
// When changing the recovery url, beware that any previously gathered recovery urls would be invalid, unless backwards
// compatibility is added.
export const UNIFIED_CART_RECOVERY = `${SHOP_BASE}cart-recovery/`;
export const LEGACY_PORTING = '/checkout/nummerflytning/';
export const SUBSCRIPTION_PRODUCT_PAGE_BASE = `${SHOP_BASE}abonnementer/`;
export const SUBSCRIPTION_PRODUCT_PAGE = `${SUBSCRIPTION_PRODUCT_PAGE_BASE}:slug/`;

export const DEVICE_TYPE = {
  Mobiles: 'Mobiler',
  Laptops: 'Laptops',
  Tablets: 'Tablets',
  RoutersAndModems: 'Routere',
};

export const getDeviceTypeUrlSegment = (deviceType) => DEVICE_TYPE[deviceType] ?? deviceType;

// getSanitizedSegment is a helper function that replaces placeholders with actual values.
// It takes a route segment and an array of dynamic path elements as arguments.
// If the segment is dynamic (i.e., starts with a colon), it pops the last value from dynamicPaths to replace the placeholder.
// If the segment is optional (ends with a question mark) but there's no corresponding value in dynamicPaths, no error is thrown.
// For static segments, the function just returns the segment itself.
const getSanitizedSegment = (segment, dynamicPaths) => {
  const isDynamic = segment.startsWith(':');
  if (isDynamic) {
    const newSegment = dynamicPaths.pop();
    if (!newSegment && !segment.endsWith('?')) {
      // eslint-disable-next-line no-console
      console.trace(`Route segment ${segment} is required, but was undefined`);
    }
    return newSegment?.toLowerCase();
  }
  return segment;
};

// The buildRoute function generates a specific route by replacing placeholders in the given route with provided dynamic segments.
// It splits the route into segments, replaces the dynamic segments with actual values,
// and removes any undefined segments (which can happen if there was a placeholder but no corresponding dynamic value).
// The dynamicSegments are reversed, so they can be efficiently removed with pop() from right to left to match the left to right order of the route segments.
// Finally, it joins the segments back together with slashes to create the final route.
export const buildRoute = (route, ...dynamicSegments) => {
  const separator = '/';
  // Copy the dynamic segments and reverse them, so we can use pop() to efficiently remove elements in the correct order.
  const dynamicPaths = [...dynamicSegments].reverse();
  const newRoute = route
    .split(separator)
    // Replace each dynamic segment with the corresponding actual value.
    .map((segment) => getSanitizedSegment(segment, dynamicPaths))
    // Remove any undefined segments.
    .reduce((res, segment, index) => (res.length === index && segment !== undefined ? [...res, segment] : res), [])
    .join(separator);

  return addTrailingSlash(newRoute);
};
