import { gql } from '@apollo/client';
import cartFragment from '../fragments/cart';

const removeCartFromSessionMutation = gql`
  mutation removeCartFromSession {
    removeCartFromSession {
      ...Cart
    }
  }
  ${cartFragment}
`;

export default removeCartFromSessionMutation;
