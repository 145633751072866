import Bowser from 'bowser';
import browserConfiguration from '../app/invalidBrowser/validBrowserConfiguration';

const checkBrowser = (userAgent) => {
  let isInvalidBrowser = false;
  let browserName = '';
  // If no userAgent is available, bypass supported browser check
  if (userAgent) {
    const { invalidBrowsers } = browserConfiguration;
    const browser = Bowser.getParser(userAgent);
    isInvalidBrowser = browser.satisfies(invalidBrowsers);
    browserName = browser.getBrowserName();
  }
  return {
    isInvalidBrowser,
    browserName,
  };
};

export default checkBrowser;
