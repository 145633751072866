import PropTypes from 'prop-types';

const propTypes = {
  checkout: {
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        quantity: PropTypes.number,
      }),
    ),
  },
};

export default propTypes;
