import { gql } from '@apollo/client';

const getDeliveryOptionsQuery = gql`
  query getDeliveryOptions {
    getDeliveryOptions {
      deliveryOption
    }
  }
`;

export default getDeliveryOptionsQuery;
