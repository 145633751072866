import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const PageHelmet = (props) => {
  const {
    pageTitle,
    metaTitle,
    metaDescription,
    metaKeywords,
    openGraphTitle,
    openGraphType,
    openGraphDescription,
    openGraphImage,
    hideFromSearchEngines,
    canonicalUrl,
    baseUrl,
    children,
    ...rest
  } = props;

  const { url, description, height, width } = openGraphImage || {};
  const { pathname } = useLocation();
  const openGraphImageIsDefined = openGraphImage && Object.keys(openGraphImage).length !== 0;

  // Handle canonical url for external pages
  let modifiedCanonicalUrl = canonicalUrl;
  if (canonicalUrl && baseUrl) {
    // Make sure pathname and baseurl match in formation
    const trimmedPathname = pathname.replace(/^\/+/, '');
    const trimmedBaseUrl = baseUrl.replace(/^\/+/, '');
    // Replace the base url in pathname with canonical url to create the fully qualifying canonical url
    modifiedCanonicalUrl = trimmedPathname.replace(trimmedBaseUrl, canonicalUrl);
  }

  return (
    <Helmet title={metaTitle || pageTitle} {...rest}>
      {metaDescription ? <meta name="description" content={metaDescription} /> : null}
      {metaKeywords?.length > 0 ? <meta name="keywords" content={metaKeywords.join(',')} /> : null}
      {openGraphTitle ? <meta property="og:title" content={openGraphTitle} /> : null}
      {openGraphType ? <meta property="og:type" content={openGraphType} /> : null}
      {openGraphDescription ? <meta property="og:description" content={openGraphDescription} /> : null}
      {openGraphImageIsDefined ? <meta property="og:image" content={url} /> : null}
      {url ? <meta property="og:image:url" content={url} /> : null}
      {description ? <meta property="og:image:alt" content={description} /> : null}
      {height ? <meta property="og:image:height" content={height} /> : null}
      {width ? <meta property="og:image:width" content={width} /> : null}
      {hideFromSearchEngines && <meta name="robots" content="noindex" />}
      {canonicalUrl && <link rel="canonical" href={modifiedCanonicalUrl} />}
      {children}
    </Helmet>
  );
};

PageHelmet.propTypes = {
  pageTitle: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  metaKeywords: PropTypes.arrayOf(PropTypes.string),
  openGraphTitle: PropTypes.string,
  openGraphType: PropTypes.string,
  openGraphDescription: PropTypes.string,
  openGraphImage: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  hideFromSearchEngines: PropTypes.bool,
  canonicalUrl: PropTypes.string,
  baseUrl: PropTypes.string,
  children: PropTypes.node,
};

PageHelmet.defaultProps = {
  pageTitle: null,
  metaTitle: null,
  metaDescription: null,
  metaKeywords: [],
  openGraphTitle: null,
  openGraphType: null,
  openGraphDescription: null,
  openGraphImage: {},
  hideFromSearchEngines: false,
  canonicalUrl: null,
  baseUrl: null,
  children: null,
};

export default PageHelmet;
