import { gql } from '@apollo/client';

export const legacyPriceFragment = gql`
  fragment legacyPriceFragment on LegacyPrice {
    bindingPeriod
    discountedRecurringPrice
    discountedUpfrontPrice
    recurringDiscount
    regularRecurringPrice
    regularUpfrontPrice
    totalDiscount
    totalDiscountedPrice
    totalDiscountedRecurringPrice
    totalRecurringDiscount
    totalRegularPrice
    totalRegularRecurringPrice
    upfrontDiscount
  }
`;

export const legacyDeviceFragment = gql`
  fragment legacyDeviceFragment on LegacyDevice {
    id
    psid
    name
    brand
    size
    color
    image
    price {
      ...legacyPriceFragment
    }
    basketSplash
    basketDiscountText
    hideProductPrice
    type
  }
  ${legacyPriceFragment}
`;

export const legacySubscriptionFragment = gql`
  fragment legacySubscriptionFragment on LegacySubscription {
    id
    basketRowId
    psid
    name
    displayName
    customerType
    subscriptionType
    type
    activationPrice
    minimumPrice
    image
    basketDiscountText
    basketSplashText
    dataLimit
    dataLimitFree
    talkTime
    talkTimeFree
    smsAndMmsFree
    onlySellAsPorting
    onlySellAsNewSale
    defaultToESim
    options {
      costControl
      directoryServiceType
      msisdn
      searchEngineVisibilityType
      subscriptionSaleType
    }
    price {
      ...legacyPriceFragment
    }
  }
  ${legacyPriceFragment}
`;

export const legacyAddonsFragment = gql`
  fragment legacyAddonsFragment on LegacyAddons {
    id
    name
    heading
    bannerText
    basketDiscountText
    image
    psid
    price {
      ...legacyPriceFragment
    }
  }
  ${legacyPriceFragment}
`;

export const legacyFreightFragment = gql`
  fragment legacyFreightFragment on LegacyFreight {
    psid
    price
    deliveryType
    deliveryMethod
    deliveryMethodDescription
  }
`;

export const legacyDeliveryAddressFragment = gql`
  fragment legacyDeliveryAddressFragment on LegacyDeliveryAddress {
    isSima
    hasHeavyAccessory
    firstName
    lastName
    careOf
    streetName
    city
    zipCode
    country
    psid
    firm
  }
`;

export const legacyAccessoriesFragment = gql`
  fragment legacyAccessoriesFragment on LegacyAccessories {
    id
    name
    displayName
    image
    price {
      ...legacyPriceFragment
    }
    psid
  }
  ${legacyPriceFragment}
`;

export const legacySimCardFragment = gql`
  fragment legacySimCardFragment on LegacySimCard {
    id
    name
    isEmbeddableSim
  }
`;

const legacyCartFragment = gql`
  fragment legacyCartFragment on LegacyCart {
    cartId
    minimumPriceSummary
    totalUpfrontPrice
    totalInstallmentAmount
    priceSummary {
      ...legacyPriceFragment
    }
    freight {
      ...legacyFreightFragment
    }
    installments {
      term
      amount
    }
    products {
      id
      bindingPeriod
      monthlyInstallmentPeriod
      subscription {
        ...legacySubscriptionFragment
      }
      addons {
        ...legacyAddonsFragment
      }
      accessories {
        ...legacyAccessoriesFragment
      }
      device {
        ...legacyDeviceFragment
      }
      simCard {
        ...legacySimCardFragment
      }
    }
    tradeInDevices {
      answers
      id
      imei
      pictureUrl
      price
      priceQuoteId
      productNameShort
    }
    tradeInCampaignDiscount {
      campaignId
    }
    customerInfo {
      email
      phoneNumber
      firstName
      lastName
      deliveryAddress {
        ...legacyDeliveryAddressFragment
      }
    }
    vouchers {
      code
      headline
      description
    }
    dcAccessories {
      ...legacyAccessoriesFragment
    }
    tradeInProducts {
      tradeInDiscount
      basketRowGuid
      devices {
        answers
        id
        imei
        pictureUrl
        price
        priceQuoteId
        productNameShort
      }
      tradeInCampaignDiscount {
        campaignId
        discount
      }
    }
    accountLevelDiscount {
      title
      description
      priceDescription
      icon
      discount
    }
  }
  ${legacyAddonsFragment}
  ${legacyAccessoriesFragment}
  ${legacyPriceFragment}
  ${legacySimCardFragment}
  ${legacyDeviceFragment}
  ${legacySubscriptionFragment}
  ${legacyFreightFragment}
  ${legacyDeliveryAddressFragment}
`;

export default legacyCartFragment;
