import { gql } from '@apollo/client';
import legacyCartFragment from '../fragments/legacyCart';

const removeBasketRowFromLegacyCartMutation = gql`
  mutation RemoveBasketRowFromLegacyCart($basketRowId: String!) {
    removeBasketRowFromLegacyCart(basketRowId: $basketRowId) {
      status
      message
      response {
        ...legacyCartFragment
      }
    }
  }
  ${legacyCartFragment}
`;

export default removeBasketRowFromLegacyCartMutation;
