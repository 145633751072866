import { Component } from 'react';
import { error as logError } from '@hi3g-access/client-logger';

import { Container } from '@3dk/3style';
import PropTypes from 'prop-types';

// This is added temporarily
const DefaultErrorBoundaryView = () => (
  <Container>Vi har registreret fejlen og forsøger at udbedre den hurtigst muligt.</Container>
);

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(errorMessage, errorInfo) {
    logError(`Error boundary hit. message: ${errorMessage.toString()}`, errorInfo.componentStack);
  }

  render() {
    const { children, ErrorComponent } = this.props;
    const { hasError } = this.state;
    if (!hasError) {
      return children;
    }

    return <ErrorComponent />;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  ErrorComponent: PropTypes.elementType,
};

ErrorBoundary.defaultProps = {
  ErrorComponent: DefaultErrorBoundaryView,
};

export default ErrorBoundary;
