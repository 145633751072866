import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@3dk/3style';
import { Link as RouterLink } from 'react-router-dom';

const Link = forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const LinkButton = ({ to, children, ...rest }) => (
  <Button component={Link} to={to} {...rest}>
    {children}
  </Button>
);

LinkButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
};

LinkButton.defaultProps = {
  to: '/',
};

export default LinkButton;
