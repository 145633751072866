const CHECKOUT_STEPS = {
  OPEN_CHECKOUT: 1,
  LOGIN: 2,
  NUMBER_SELECTION: 3,
  CREDIT_CHECK: 4,
  DELIVERY_SELECTION: 5,
  ACCEPT: 6,
};

export default CHECKOUT_STEPS;
