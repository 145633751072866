import categories from '../constants/categories';
import { OWN_BRAND } from '../constants/custom-brand';

/**
 * Those methods should only be used when mapping VAS to datalayer
 *
 * VAS should have the same structure and almost the same data,
 * it's case-sensitive
 */
export const mapVasToProduct = (vas) => ({
  name: vas.name,
  id: vas.name,
  webId: vas.id,
  price: vas.price?.discountedRecurringPrice ?? 0,
  brand: OWN_BRAND,
  category: categories.ADDON,
});

export const mapInsuranceToDatalayer = (insurance) => ({
  name: insurance.name,
  id: insurance.name,
  webId: insurance.id,
  price: insurance.monthlyPrice,
  brand: OWN_BRAND,
  category: categories.ADDON,
});
