import { Footer, IconMap } from '@3dk/3style';
import { useQuery } from '@apollo/client';
import WidthWrapper from '../../components/WidthWrapper';
import getFooterQuery from '../../apollo/queries/getFooter';
import ApolloErrorHandler from '../../components/errorHandling/ApolloErrorHandler';
import LinkResolver from '../../components/LinkResolver';
import LINK_TYPES from '../../constants/linkTypes';

const classes = {
  root: {
    backgroundColor: 'black',
  },
};

const FooterContainer = () => {
  const { data, error, loading } = useQuery(getFooterQuery);

  if (loading) return null;
  if (error) return <ApolloErrorHandler error={error} />;

  const { address, copyright, cvr, footerSections, socialMediaUSPs } = data.footer || {};

  const socialMediaItems = socialMediaUSPs?.map((usp) => ({
    icon: IconMap[usp.icon],
    src: usp.link?.url,
  }));

  const footerColumns = footerSections?.map((section) => ({
    header: section.header,
    links: section.links.map((link) => ({
      text: link.text,
      component: LinkResolver,
      linkType: link.linkType,
      href: link.url, // used to satisfy <FooterSection /> PropTypes (doesn't seem to be used)
      url: link.url, // used to set attributes in <LinkResolver />
      pageType: link.linkType === LINK_TYPES.ENTRY ? link.internalLink : undefined,
      key: link.url,
    })),
  }));

  return (
    <div css={classes.root}>
      <WidthWrapper>
        <Footer
          columns={footerColumns}
          address={address}
          cvr={cvr}
          copyright={copyright}
          socialMediaItems={socialMediaItems}
        />
      </WidthWrapper>
    </div>
  );
};

export default FooterContainer;
