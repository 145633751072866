import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ACCESSORY_BASE } from 'constants/urls';

const ACCESSORY_BASE_AS_REGEX = new RegExp(`\\${ACCESSORY_BASE}.*`, 'i');

/**
 * Custom Cart Configuration hook
 * @returns {(Object)}
 * useDCBasket: true if DC basket should be used, default is false.
 * showLegacyData: true if legacy products should be shown in the basket, default is false.
 * loading: true while loading.
 */
const useCartConfiguration = () => {
  const location = useLocation();

  const [useDCBasket, setUseDCBasket] = useState(false);
  const [showLegacyData, setShowLegacyData] = useState(false);

  useEffect(() => {
    if (!location.pathname) return;

    const currentLocation = location.pathname;
    const isCurrentLocationADCPage = ACCESSORY_BASE_AS_REGEX.test(currentLocation);
    const shouldUseDCBasket = isCurrentLocationADCPage;
    const shouldShowLegacyData = !shouldUseDCBasket;

    setUseDCBasket(shouldUseDCBasket);
    setShowLegacyData(shouldShowLegacyData);
  }, [location]);
  return { useDCBasket, showLegacyData };
};

export default useCartConfiguration;
