import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GlobalStyles, ThemeProvider, businessTheme } from '@3dk/3style';
import { configure, error as logError, info as logInfo } from '@hi3g-access/client-logger';
import { v4 as uuidv4 } from 'uuid';
import loadable from '@loadable/component';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ApolloProvider, useQuery } from '@apollo/client';
import { PublicConfigAPI } from 'react-imgix';
import useCartConfiguration from 'utils/hooks/useCartConfiguration';
import useLegacyCart from 'utils/hooks/useLegacyCartHook';
import useCart from 'utils/hooks/useCartHook';
import Cookies from 'js-cookie';
import FETCH_STATE from 'constants/fetchState';
import { DefaultHelmet, AgentHelmet, SplitTestingHelmet } from './utils/helmets';
import RedirectWithTrailingSlash from './components/RedirectWithTrailingSlash';
import ErrorBoundary from './components/ErrorBoundary';
import InvalidBrowser from './app/InvalidBrowser';
import { setBypassUnsupportedBrowser } from './utils/cookieHelpers/cookies';
import { name } from '../package.json';
import runtimeConfig from './utils/config';
import { CONFIRMATION_SELECTION, NEM_ID_FALLBACK_VERIFICATION, NEM_ID_VERIFICATION } from './constants/urls';
import menuMediaQuery from './constants/menuMediaQuery';
import Menu from './app/Menu';
import useFirstRender from './utils/useFirstRender';
import syncSessionQuery from './apollo/queries/syncSession';
import FooterContainer from './app/routes/FooterContainer';
import PRODUCT_TYPES from './constants/productTypes';
import FEATURE_FLAG from './utils/feature-flags';
import localBusinessTheme from './app/business/utils/constants/businessTheme';
import BridgeUpsaleContainer from './components/BridgeUpsaleContainer';
import { useFeatureFlagContext } from './optimizely';
import {
  BUSINESS_AGENT_BASE,
  BUSINESS_BASE,
  BUSINESS_CUSTOMER_CONFIRM_BASE,
} from './app/business/utils/constants/paths';
import checkBrowser from './utils/checkBrowser';

// disables No fallback <img /> or <Imgix /> found in the children...cause by complex children
// see https://github.com/imgix/react-imgix/issues/164 for further explanation
PublicConfigAPI.disableWarning('fallbackImage');

const menuPaddingQuotient = 100 * (75 / 667);

const classes = {
  root: {
    minHeight: '100vh', // Ensure that menu is closed when user clicks outside of menu. Also ensures children are not restricted by height.
    display: 'flex',
    flexDirection: 'column',
    '@media (min-aspect-ratio: 13/9) and (max-width: 768px)': {
      paddingLeft: `${menuPaddingQuotient}vw`, //  Horizontal devices with 697px < screen width < 769px,
      // the menu width is set to the equivalent of 75px on a 667px wide screen.
    },
  },
  menuPadding: {
    [menuMediaQuery]: {
      paddingLeft: '90px', // Menu width
    },
  },
  agentPageContainer: {
    display: 'flex',
  },
  mainContent: {
    flexGrow: 1,
  },
};

// Logger setup
// import { info as logInfo, warn as logWarning, error as logError } from '@hi3g-access/client-logger';
// usage:
// info(message, {params}), warn(message, {params}) or error(message, {params});
// params can be excluded
configure({
  context: {
    application: name,
  },
  endpoint: runtimeConfig.CLIENT_LOGGER_ENDPOINT,
  watch: true,
});

const LoginPage = loadable(() => import('./app/LoginPage'));
const AgentRoutes = loadable(() => import('./app/AgentRoutes'));
const BusinessRoutes = loadable(() => import('./app/business/BusinessRoutes'));
const Routes = loadable(() => import('./app/Routes'));

const VERIFICATION_ROUTES = [NEM_ID_VERIFICATION, NEM_ID_FALLBACK_VERIFICATION];
const NO_FOOTER_ROUTES = [
  VERIFICATION_ROUTES,
  BUSINESS_CUSTOMER_CONFIRM_BASE,
  BUSINESS_AGENT_BASE,
  CONFIRMATION_SELECTION,
].flat();

// TODO: Refactor this to a better solution
const addFeatureCookie = () => {
  const { CookieInformation } = window || {};
  if (CookieInformation.getConsentGivenFor('cookie_cat_functional')) {
    if (!Cookies.get('feat_id')) {
      Cookies.set('feat_id', uuidv4(), { expires: 365 });
    }
  }
};

const App = ({ userAgent, bypassUnsupportedBrowser, businessClient }) => {
  const [menuFetchState, setMenuFetchState] = useState(FETCH_STATE.NONE);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { showLegacyData } = useCartConfiguration();
  const { getFeatureFlag } = useFeatureFlagContext();

  // Prefetch
  const { cartData, cartError } = useCart();
  const { legacyCartData, legacyCartError } = useLegacyCart();

  // TODO: Refactor this to a better solution
  if (typeof window !== 'undefined') {
    addFeatureCookie();
  }

  // TODO: Refactor this to a better solution
  useEffect(() => {
    window.addEventListener('CookieInformationConsentGiven', addFeatureCookie, false);
    return () => {
      window.removeEventListener('CookieInformationConsentGiven', addFeatureCookie);
    };
  }, []);

  // Make a request every time application loads, to synchronize session from My3 with Acc. shop
  const { error: errorSyncingSession } = useQuery(syncSessionQuery, { ssr: false });
  const [hasAcceptedBypass, setAcceptedBypass] = useState(bypassUnsupportedBrowser);
  const firstRender = useFirstRender();

  if (errorSyncingSession) logError('Error when syncing session', { errorSyncingSession });
  if (cartError) logError(`Error when getting cart: ${cartError}`);
  if (legacyCartError) logError(`Error when getting legacy cart: ${legacyCartError}`);

  const { isInvalidBrowser, browserName } = checkBrowser(userAgent);
  // If no userAgent is available, bypass supported browser check
  if (isInvalidBrowser && firstRender) {
    logInfo({ message: 'Unsupported browser with user agent', userAgent, browserName });
  }

  const setBypass = () => {
    setAcceptedBypass(true);
    setBypassUnsupportedBrowser(true);
  };
  const cart = cartData?.cart;
  const legacyCart = showLegacyData ? legacyCartData?.legacyCart : {};

  const cartOfferCount = cart?.items?.length || 0;

  // Subscription + Device is counted as a single product, thus counting of devices is omitted
  const legacyCartOfferCount =
    legacyCart?.products?.reduce((count, product) => {
      let newCount = count;
      newCount += product.accessories?.length ?? 0;
      newCount += product.addons?.length ?? 0;
      newCount += product.subscription ? 1 : 0;
      return newCount;
    }, 0) || 0;
  const countOfProductsInCart = cartOfferCount + legacyCartOfferCount;
  const totalInstallmentPrice = cart?.summarizedOfferingPrices?.totalInstallmentCost?.inclVat || 0;
  const countOfDataSubscriptionsInCart =
    cart?.items?.filter(
      (cartItem) =>
        cartItem.offering.product.type === PRODUCT_TYPES.PRICE_PLAN ||
        cartItem.offering.product.primaryProduct?.type === PRODUCT_TYPES.PRICE_PLAN,
    ).length || 0;

  // Variables and functions needed for the menu
  const location = useLocation();
  const { pathname } = location;
  const verificationRoute = VERIFICATION_ROUTES.includes(pathname); // Prevent rendering certain components on verification routes (used in iframes)

  const isCustomerConfirm = pathname.startsWith(CONFIRMATION_SELECTION);
  const showMenu = !isCustomerConfirm && !verificationRoute;
  const showFooter = NO_FOOTER_ROUTES.map((route) => pathname.includes(route)).filter(Boolean).length === 0; // Prevent rendering footer on certain base paths

  const onCloseMenu = () => {
    if (menuFetchState === FETCH_STATE.SUCCESS) {
      const closeButton = document.querySelector('nav > div:last-of-type a[class*="closeButton"]');
      if (closeButton) {
        closeButton.click();
      }
    }
  };

  const splitTestingEnabled = getFeatureFlag(FEATURE_FLAG.SPLIT_TESTING_ENABLED, false);

  return (
    <>
      {hasAcceptedBypass || !isInvalidBrowser ? (
        <ErrorBoundary>
          <RedirectWithTrailingSlash />
          <GlobalStyles />
          <Switch>
            <Route
              exact
              path="/agent/login"
              render={() => (
                <>
                  <AgentHelmet />
                  <LoginPage />
                </>
              )}
            />
            <Route
              path="/agent"
              render={() => (
                <>
                  <AgentHelmet />
                  <div css={classes.agentPageContainer}>
                    <AgentRoutes
                      isCartOpen={isCartOpen}
                      setIsCartOpen={setIsCartOpen}
                      countOfProductsInCart={countOfProductsInCart}
                      totalInstallmentPrice={totalInstallmentPrice}
                      countOfDataSubscriptionsInCart={countOfDataSubscriptionsInCart}
                    />
                  </div>
                </>
              )}
            />
            <Route
              path={BUSINESS_BASE}
              render={() => (
                <>
                  <DefaultHelmet />
                  <Menu
                    setMenuFetchState={setMenuFetchState}
                    menuFetchState={menuFetchState}
                    showMenu={showMenu}
                    isBusiness
                  />
                  <ApolloProvider client={businessClient}>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div
                      onClick={onCloseMenu}
                      // Omit menu padding on loading failure to avoid the menu from shifting the site to the right.
                      css={[classes.root, showMenu && classes.menuPadding]}
                    >
                      <div css={classes.mainContent}>
                        <ThemeProvider
                          themeProviderProps={{
                            ...businessTheme,
                            palette: {
                              ...businessTheme.palette,
                              ...localBusinessTheme.palette,
                            },
                          }}
                        >
                          <BusinessRoutes />
                        </ThemeProvider>
                      </div>
                      {showFooter && <FooterContainer />}
                    </div>
                  </ApolloProvider>
                </>
              )}
            />
            <Route
              render={() => (
                <>
                  {splitTestingEnabled ? <SplitTestingHelmet /> : <DefaultHelmet />}
                  <Menu menuFetchState={menuFetchState} setMenuFetchState={setMenuFetchState} showMenu={showMenu} />
                  <BridgeUpsaleContainer />
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                  <div
                    onClick={onCloseMenu}
                    // Omit menu padding on loading failure to avoid the menu from shifting the site to the right.
                    css={[classes.root, showMenu && classes.menuPadding]}
                  >
                    <div css={classes.mainContent}>
                      <Routes
                        countOfProductsInCart={countOfProductsInCart}
                        isCartOpen={isCartOpen}
                        setIsCartOpen={setIsCartOpen}
                      />
                    </div>
                    {showFooter && <FooterContainer />}
                  </div>
                </>
              )}
            />
          </Switch>
        </ErrorBoundary>
      ) : (
        <InvalidBrowser browserName={browserName} onClickContinue={setBypass} />
      )}
    </>
  );
};

App.propTypes = {
  userAgent: PropTypes.string.isRequired,
  bypassUnsupportedBrowser: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  businessClient: PropTypes.any,
};

App.defaultProps = { businessClient: null };

export default App;
