export const BUSINESS_BASE = '/business/';

// Business Customer Confirm
export const BUSINESS_CUSTOMER_CONFIRM_BASE = `${BUSINESS_BASE}tilbud/`;
export const BUSINESS_OFFER_OVERVIEW_PAGE = `${BUSINESS_CUSTOMER_CONFIRM_BASE}:customerId/`;
export const BUSINESS_OFFER_PAGE = `${BUSINESS_OFFER_OVERVIEW_PAGE}:orderReference/`;
export const BUSINESS_RECEIPT_PAGE_ACCEPT = `${BUSINESS_OFFER_PAGE}kvittering/accepted`;
export const BUSINESS_RECEIPT_PAGE_DECLINE = `${BUSINESS_OFFER_PAGE}kvittering/declined`;
export const BUSINESS_RECEIPT_PAGE_EXPIRED = `${BUSINESS_OFFER_PAGE}kvittering/expired`;

// Business Agent
export const BUSINESS_AGENT_BASE = `${BUSINESS_BASE}agent/`;
export const BUSINESS_AGENT_LOGIN_PAGE = `${BUSINESS_AGENT_BASE}login`;
export const BUSINESS_OFFER_PAGE_AGENTS = `${BUSINESS_AGENT_BASE}tilbud/:orderReference/`;
