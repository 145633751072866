import { gql } from '@apollo/client';
import unifiedCartFragment from 'apollo/fragments/unifiedCart';

const getUnifiedCartQuery = gql`
  query getUnifiedCart {
    unifiedCart {
      ...unifiedCartFragment
    }
  }
  ${unifiedCartFragment}
`;

export default getUnifiedCartQuery;
