import { gql } from '@apollo/client';
import footerSectionFragment from '../fragments/footerSection';
import linkFragment from '../fragments/link';

const getFooterQuery = gql`
  query getFooter {
    footer {
      address
      cvr
      copyright
      socialMediaUSPs {
        __typename
        ... on ContentfulUSPs {
          icon
          link {
            ...LinkFragment
          }
        }
        ... on IconBlock {
          icon
          link {
            ...LinkFragment
          }
        }
      }
      footerSections {
        ...FooterSectionFragment
      }
    }
  }
  ${linkFragment}
  ${footerSectionFragment}
`;

export default getFooterQuery;
