import { Helmet } from 'react-helmet-async';

export const DefaultHelmet = () => <Helmet titleTemplate="%s | 3.dk" defaultTitle="3.dk" />;

export const SplitTestingHelmet = () => (
  <Helmet titleTemplate="%s | 3.dk" defaultTitle="3.dk">
    <script type="text/javascript" src="https://cdn.optimizely.com/js/27454060537.js" />
  </Helmet>
);

export const AgentHelmet = () => <Helmet titleTemplate="AS: %s" defaultTitle="AS: Agent Sales" />;
