import { error as errorLog } from '@hi3g-access/client-logger';
import {
  getEcommerceDevice,
  orderSummaryToPurchase,
  getUpsaleAddedProduct,
  getTargetProduct,
  getOneClickBasketProducts,
  getRemovedItemsFromLegacyBasket,
  generateProductsInLegacyCart,
  mapBasketRowsToDatalayer,
} from './utils/legacyCartHelper';
import categories from './constants/categories';
import events from './constants/events';
import propTypes from './constants/propTypes';
import CHECKOUT_TYPES from './constants/checkoutTypes';
import { deviceListItemToDataLayer, mapDeviceToDataLayer, getAdditionalInfo } from './mappers/legacyDeviceMapper';
import {
  addToCartEvent,
  clickOnListItemEvent,
  detailsView,
  ecommerceCheckoutEvent,
  impressionEvent,
  purchaseEvent,
  removeFromCartEvent,
} from './utils/eventWrappers';
import { mapAccessory, mapBaseAccessory, mapVariantToImpressionView } from './mappers/accessoryMapper';
import { mapSubscriptionPageToDayaLayer, mapSubscriptionToDayaLayer } from './mappers/subscriptionMapper';
import runtimeConfig from '../config';
import { mapInsuranceToDatalayer } from './mappers/vasMapper';
import { generateProductsInCart, getCartPurchaseActionField, getDeletedOffer } from './utils/cartHelper';
import CWA_STATUS from '../../constants/cwaStatus';
import ORDER_DECISIONS from '../../constants/orderDecisions';
import CHECKOUT_STEPS from './constants/checkoutSteps';

/*
 * Evaluates the presence of window object.
 * Referenced multiple times because it may not be present at the time the callback function is invoked
 */
const hasWindow = () => typeof window !== 'undefined';

const pushEvent = (event, data) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    ...(event && { event }),
    ...data,
  });
};

// Reference: https://support.cookieinformation.com/en/articles/5444629-third-party-cookie-blocking
// Has given required 'statistics' consent for us to push events
const hasGivenRequiredConsent = () => {
  if (hasWindow()) {
    const { CookieInformation } = window || {};

    return CookieInformation && CookieInformation.getConsentGivenFor('cookie_cat_statistic');
  }
  return false;
};

/*
 * Evaluates if user has given any consent at all
 * The docs outline the three cookie categories we have to consider: cookie_cat_functional, cookie_cat_statistic, cookie_cat_marketing.
 * We exclude cookie_cat_necessary (also mentioned in docs), because this is actually set before user has given any consent at all.
 */
const hasAlreadyGivenMinimalConsent = () => {
  if (hasWindow()) {
    const { CookieInformation } = window || {};
    return (
      CookieInformation.getConsentGivenFor('cookie_cat_functional') ||
      CookieInformation.getConsentGivenFor('cookie_cat_marketing')
    );
  }
  return false;
};

export const consentEventListener = (event, data) => {
  if (hasGivenRequiredConsent()) {
    pushEvent(event, data);
  }
};

const translateLoggedInType = (role, authenticationMethod) => {
  if (authenticationMethod && authenticationMethod.toLowerCase() === 'nemid') return 'MitID';
  if (role) {
    if (role.toLowerCase() === 'subscriber') return 'Msisdn';
    return 'Email';
  }
  return '';
};

const safeFunction =
  (func) =>
  (...args) => {
    try {
      func(...args);
    } catch (e) {
      errorLog('Datalayer event got unexpected error', { error: e });
      // this should only be safe in prod, we want to see datalayer errors in development and test
      if (runtimeConfig.NODE_ENV !== 'production') {
        throw e;
      }
    }
  };

const dataLayer = {
  /*
   * Three things can happen when we try to push event (Note: we need 'statistics' cookie consent to track user):
   *  1. The user has given proper consent and we push the event.
   *  2. The user has not yet given any consent, in this case we set up event listener with the event, and push it once and if they provide consent at a later time.
   *  3. The user has given consent, but required (e.g. 'statistics'), and thus we are not allowed to track them.
   */
  pushEvent: (event, data) => {
    if (hasWindow()) {
      if (hasGivenRequiredConsent()) {
        pushEvent(event, data);
      } else if (!hasAlreadyGivenMinimalConsent()) {
        window.addEventListener('CookieInformationConsentGiven', () => consentEventListener(event, data), {
          // make sure we only invoke function once per event, by cleaning up and removing event listener, when invoked
          once: true,
        });
      }
    }
  },
  addAccessoryToCart: safeFunction((accessory, selectedVariant) => {
    const event = addToCartEvent([mapAccessory({ ...accessory, selectedVariant })]);
    dataLayer.pushEvent(events.ADD_TO_CART, event);
  }),
  accessoryListImpression: safeFunction((accessories, list) => {
    const impressions = accessories.map((accessory, index) => mapVariantToImpressionView(accessory, list, index));
    dataLayer.pushEvent(events.PRODUCT_IMPRESSIONS, impressionEvent(impressions));
  }),
  accessoryDetails: safeFunction((accessory, selectedVariant) => {
    const event = detailsView([mapAccessory({ ...accessory, selectedVariant })]);
    dataLayer.pushEvent(events.PRODUCT_DETAIL_IMPRESSIONS, event);
  }),
  legacyDeviceListImpression: safeFunction((productListData, list) => {
    const products = productListData?.products || [];
    const impressions = products.map((product, index) => deviceListItemToDataLayer(product, index, list));
    dataLayer.pushEvent(events.PRODUCT_IMPRESSIONS, impressionEvent(impressions));
  }),
  productListClickEvent: safeFunction((product, position, list, isDevice) => {
    const products = isDevice
      ? deviceListItemToDataLayer(product, position, list)
      : mapVariantToImpressionView(product, list, position);
    const ecommerce = clickOnListItemEvent(products, isDevice ? product.type : 'Related Products');
    dataLayer.pushEvent(events.PRODUCT_CLICKS, ecommerce);
  }),
  addLegacyItemToCart: safeFunction((data, queryVariables, insuranceSelected) => {
    const ecommerceDevice = getEcommerceDevice(data, queryVariables);
    const products = [
      mapDeviceToDataLayer(ecommerceDevice),
      mapSubscriptionToDayaLayer(ecommerceDevice.subscription, false),
    ];
    if (insuranceSelected && ecommerceDevice.insurance) {
      products.push(mapInsuranceToDatalayer(ecommerceDevice.insurance));
    }
    const ecommerce = addToCartEvent(products);
    dataLayer.pushEvent(events.ADD_TO_CART, ecommerce);
  }),
  legacyDeviceDetails: safeFunction((data, queryVariables) => {
    const ecommerceDevice = getEcommerceDevice(data, queryVariables);
    const detailView = detailsView([
      mapDeviceToDataLayer(ecommerceDevice),
      mapSubscriptionToDayaLayer(ecommerceDevice.subscription, false),
    ]);
    detailView.ecommerce = {
      ...detailView.ecommerce,
      ...getAdditionalInfo(ecommerceDevice),
    };

    dataLayer.pushEvent(events.PRODUCT_DETAIL_IMPRESSIONS, detailView);
  }),
  legacySubscriptionDetails: safeFunction((subscription) => {
    const subscriptionView = detailsView([mapSubscriptionPageToDayaLayer(subscription)]);
    dataLayer.pushEvent(events.PRODUCT_DETAIL_IMPRESSIONS, subscriptionView);
  }),
  addLegacySubscriptionToCart: safeFunction((subscription) => {
    const subscriptionData = addToCartEvent([mapSubscriptionPageToDayaLayer(subscription)]);
    dataLayer.pushEvent(events.ADD_TO_CART, subscriptionData);
  }),
  addUpsaleToCart: safeFunction((basket, rowId, addedId) => {
    const product = getUpsaleAddedProduct(basket.data.addProductToLegacyCart.response.products, rowId, addedId);
    dataLayer.pushEvent(events.ADD_TO_CART, addToCartEvent([product], 'upsell'));
  }),
  removeUpsaleFromCart: safeFunction((row, removedId) => {
    const products = getTargetProduct(row, removedId);
    dataLayer.pushEvent(events.REMOVE_FROM_CART, removeFromCartEvent(products));
  }),
  oneClickBasketEvent: safeFunction((products, productsAdded) => {
    const datalayerProducts = getOneClickBasketProducts(products, productsAdded);
    dataLayer.pushEvent(events.ADD_TO_CART, addToCartEvent(datalayerProducts));
  }),
  oneClickBasketDetailsEvent: safeFunction((products) => {
    const datalayerProducts = mapBasketRowsToDatalayer(products);
    dataLayer.pushEvent(events.PRODUCT_DETAIL_IMPRESSIONS, detailsView(datalayerProducts));
  }),
  cwaResultEvent: safeFunction((cwaData) => {
    dataLayer.pushEvent(events.EXTERNAL_INSTALLMENT_CHECK, {
      installmentStatus: CWA_STATUS.ACCEPT === cwaData.decision,
      installmentCredit: cwaData.availableMonthlyAmount,
    });
  }),
  creditCheckResultEvent: safeFunction((creditCheckData) => {
    dataLayer.pushEvent(events.INTERNAL_INSTALLMENT_CHECK, {
      installmentStatus: ORDER_DECISIONS.ACCEPT === creditCheckData.data.attributes.decision[0],
      installmentResponse: creditCheckData.data.attributes.decision.join(', '),
    });
  }),
  legacyProductRemoved: safeFunction((param, legacyCartData) => {
    const removedProducts = getRemovedItemsFromLegacyBasket(param, legacyCartData.legacyCart.products);
    dataLayer.pushEvent(events.REMOVE_FROM_CART, removeFromCartEvent(removedProducts));
  }),
  productRemoved: safeFunction((offerId, cartData) => {
    const products = [getDeletedOffer(offerId, cartData.cart.items)];
    dataLayer.pushEvent(events.REMOVE_FROM_CART, removeFromCartEvent(products));
  }),
  pushPreDcCheckoutEvent: safeFunction((step, legacyCart, cart) => {
    const legacyProducts = generateProductsInLegacyCart(legacyCart);
    const dcProducts = generateProductsInCart(cart, true);
    const ecommerce = ecommerceCheckoutEvent(step, legacyProducts.concat(dcProducts), CHECKOUT_TYPES.WEBSHOP);
    dataLayer.pushEvent(dataLayer.events.CHECKOUT, ecommerce);
  }),
  generatePurchaseEcommerce: safeFunction((cart, hasPrice, orderId) => {
    const products = generateProductsInCart(cart, hasPrice);
    const ecommerce = purchaseEvent(products, getCartPurchaseActionField(cart, orderId));
    dataLayer.pushEvent(events.PURCHASE, ecommerce);
  }),
  pushCheckoutEvent: safeFunction((step, action, products) => {
    const options = action ? { action } : {};
    const ecommerce = ecommerceCheckoutEvent(step, products, CHECKOUT_TYPES.ACCESSORY, options);
    dataLayer.pushEvent(events.CHECKOUT, ecommerce);
  }),
  pushGlobalTrackingEvent: safeFunction((user) => {
    const loggedIn = user?.isLoggedIn ?? false;
    const data = {
      loggedIn,
      loggedInType: loggedIn ? translateLoggedInType(user.role, user.authenticationMethod) : '',
    };
    dataLayer.pushEvent(events.LOGIN, data);
  }),
  pushPreDcPurchaseToDataLayer: safeFunction((data) => {
    const { orderReferenceId, revenue, products, shipping } = orderSummaryToPurchase(data);
    const ecommerce = purchaseEvent(products, {
      action: 'purchase',
      affiliation: '3DK',
      id: orderReferenceId,
      revenue,
      shipping,
    });
    dataLayer.pushEvent(events.PURCHASE, ecommerce);
  }),
  relatedProductClick: safeFunction((accessory, positionInList) => {
    const ecommerce = clickOnListItemEvent(mapBaseAccessory(accessory, positionInList), 'Related Products');
    dataLayer.pushEvent(events.PRODUCT_CLICKS, ecommerce);
  }),
  pushVirtualPageEvent: safeFunction((virtualPagePath) => {
    dataLayer.pushEvent(dataLayer.events.VIRTUAL_PAGE_VIEW, { virtualPagePath });
  }),
  // These are passed on as part of the DataLayer object in order to only require a data layer import
  generateProductsInCart,
  events: { ...events },
  checkoutSteps: { ...CHECKOUT_STEPS },
  categories: { ...categories },
  propTypes: { ...propTypes },
};

export default dataLayer;
