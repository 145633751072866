import PropTypes from 'prop-types';

const FETCH_STATE = {
  // fetch() not called
  NONE: 'NONE',
  // fetch() called and failed
  FAILED: 'FAILED',
  // fetch() called and succeeded
  SUCCESS: 'SUCCESS',
};

export const fetchStateType = PropTypes.oneOf(Object.keys(FETCH_STATE));

export default FETCH_STATE;
