/* The current list of supported browsers is based on support for flex layout
 * */

const invalidBrowsers = {
  firefox: '<55',
  chrome: '<60',
  ie: '<=11', // matches all iterations of IE 11, e.g. 11.719.18362.0 and all 11.x
  safari: '<11',
  android: '<4.4',
  opera: '<47',

  mobile: {
    opera: '<44',
  },
};

const updateBrowserLinks = [
  {
    browser: 'Chrome',
    updateLink: 'https://www.google.com/chrome/',
    errorMessage: '',
    otherBrowsers: [
      { name: 'Firefox', link: 'https://www.mozilla.org/en-US/firefox/new/' },
      { name: 'Opera', link: 'https://www.opera.com/download' },
    ],
  },
  {
    browser: 'Firefox',
    updateLink: 'https://www.mozilla.org/en-US/firefox/new/',
    errorMessage: '',
    otherBrowsers: [
      { name: 'Chrome', link: 'https://www.google.com/chrome/' },
      { name: 'Opera', link: 'https://www.opera.com/download' },
    ],
  },
  {
    browser: 'Internet Explorer',
    errorMessage:
      'Microsoft har lukket for support af den version af Internet Explorer du benytter. Det betyder, at du ikke længere får sikkerhedsopdateringer og fejlrettelser, og du skal derfor opdatere til nyeste version.',
    updateLink: 'https://support.microsoft.com/en-gb/help/17621/internet-explorer-downloads',
    otherBrowsers: [
      { name: 'Chrome', link: 'https://www.google.com/chrome/' },
      { name: 'Firefox', link: 'https://www.mozilla.org/en-US/firefox/new/' },
      { name: 'Opera', link: 'https://www.opera.com/download' },
    ],
  },
  {
    browser: 'Opera',
    updateLink: 'https://www.opera.com/download',
    errorMessage: '',
    otherBrowsers: [
      { name: 'Firefox', link: 'https://www.mozilla.org/en-US/firefox/new/' },
      { name: 'Chrome', link: 'https://www.google.com/chrome/' },
    ],
  },
  {
    browser: 'Safari',
    updateLink: 'https://support.apple.com/en-us/HT204416',
    errorMessage: '',
    otherBrowsers: [
      { name: 'Chrome', link: 'https://www.google.com/chrome/' },
      { name: 'Firefox', link: 'https://www.mozilla.org/en-US/firefox/new/' },
      { name: 'Opera', link: 'https://www.opera.com/download' },
    ],
  },
  {
    browser: 'Android Browser',
    updateLink: '',
    errorMessage: '',
    otherBrowsers: [
      { name: 'Chrome', link: 'https://www.google.com/chrome/' },
      { name: 'Firefox', link: 'https://www.mozilla.org/en-US/firefox/new/' },
      { name: 'Opera', link: 'https://www.opera.com/download' },
    ],
  },
];

const browserConfiguration = {
  invalidBrowsers,
  updateBrowserLinks,
};

export default browserConfiguration;
