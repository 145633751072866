// Extracts durations from an ISO8601 period string
// E.g. period 1 year 3 months 2 days:  P1Y3M2D => { years: '1', months: '3', days: '2'}
// E.g. period 1H:                      PT1H => { hours: '1'}
// E.g. period 2 days 12 hours:         P2DT12H => { days: '2', hours: 12}
// @param period string: ISO8601 period string
const extractDurationFromPeriod = (period) => {
  const result = {};
  if (!period) return result;
  const regex = /^P((?<y>\d+)Y)?((?<m>\d+)M)?((?<d>\d+)D)?(T((?<th>\d+)H)?((?<tm>\d+)M)?((?<ts>\d+(.\d+)?)S)?)?$/;
  const match = regex.exec(period);
  const { y, m, d, th, tm, ts } = match.groups;
  if (y) result.years = y;
  if (m) result.months = m;
  if (d) result.days = d;
  if (th) result.hours = th;
  if (tm) result.minutes = tm;
  if (ts) result.seconds = ts;

  return result;
};

export default extractDurationFromPeriod;
