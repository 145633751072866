import extractDurationFromPeriod from '../../extractDurationFromPeriod';
import PaymentType from '../../../constants/paymentTypes';
import { mapLegacyAccessory } from '../mappers/accessoryMapper';
import { mapVasToProduct } from '../mappers/vasMapper';
import { generateDeviceInLegacyCart } from '../mappers/legacyDeviceMapper';
import { generateSubscriptionInLegacyCart } from '../mappers/subscriptionMapper';

export const getEcommerceDevice = (data, queryVariables) => {
  const { paymentId, subscriptionId, paymentType } = queryVariables.options;
  const { brand, name, detailedVariant, type: pageType, deviceId, insurance } = data.devicePage;
  const { sizes, vendorColorName, offering, subscriptions } = detailedVariant;
  const size = sizes.find(({ id }) => id === deviceId)?.label;

  const price = offering?.paymentPlanOptions?.upfrontPlan?.amount;
  let paymentPlan;
  let upfrontPrice;
  let installmentPlan;
  let installmentDuration;
  if (paymentId) {
    switch (paymentType) {
      case PaymentType.INSTALLMENT:
        installmentPlan = offering.paymentPlanOptions.installmentPlans.find(({ id }) => id === paymentId);
        installmentDuration = extractDurationFromPeriod(installmentPlan.duration).months;
        paymentPlan = `${installmentDuration}md`;
        upfrontPrice = 0;
        break;
      case PaymentType.UPFRONT:
        paymentPlan = 'Pay Now';
        upfrontPrice = price;
        break;
      default:
    }
  } else {
    installmentDuration = offering?.paymentPlanOptions?.installmentPlans?.[0]?.duration;
    paymentPlan = `${extractDurationFromPeriod(installmentDuration).months}md`;
    upfrontPrice = 0;
  }

  const subscription = subscriptionId
    ? subscriptions.find(({ id }) => id === subscriptionId)
    : (subscriptions || [])[0];

  return {
    size,
    vendorColorName,
    brand,
    price,
    paymentPlan,
    upfrontPrice,
    subscription,
    subscriptionMonthlyPrice: subscription?.recurringPrice ?? 0,
    name,
    deviceId,
    pageType,
    insurance,
  };
};

const mapProductToDatalayer = (row, product) => {
  const [identifier] = product?.id || '';
  switch (Number(identifier)) {
    case 4:
      return generateDeviceInLegacyCart(row.device, row.subscription);
    case 2:
      return generateSubscriptionInLegacyCart(
        row.device,
        row.subscription,
        row.simCard?.isEmbeddableSim ?? row.eSimSelected,
      );
    case 7:
      return mapLegacyAccessory(product);
    case 6:
      return mapVasToProduct(product);
    default:
      return null;
  }
};
export const getTargetProduct = (row, targetId) => {
  const addedAccessory = row.accessories.find(({ id }) => targetId === id);
  if (addedAccessory) {
    return mapLegacyAccessory(addedAccessory);
  }

  const addedAddon = row.addons.find(({ id }) => targetId === id);
  if (addedAddon) {
    return mapVasToProduct(addedAddon);
  }

  return null;
};
export const getUpsaleAddedProduct = (products, rowId, addedId) => {
  const newRow = products.find(({ id }) => id === rowId);
  return newRow ? getTargetProduct(newRow, addedId) : null;
};

const flatMapRowProducts = (product) =>
  [product.device, product.subscription, ...(product.accessories || []), ...(product.addons || [])].filter(Boolean);

const getProductRowFromIds = (rows, ids) =>
  rows.find((product) => {
    const products = flatMapRowProducts(product);
    return ids.every((id) => products.some(({ id: productId }) => id === productId));
  });

export const mapBasketRowsToDatalayer = (rows) =>
  (rows || [])
    .flatMap((row) => (row ? flatMapRowProducts(row) : []).map((product) => mapProductToDatalayer(row, product)))
    .filter(Boolean);

export const getOneClickBasketProducts = (products, addedProducts) => {
  const reversedProductList = [...products].reverse(); // latest products are at the end
  return mapBasketRowsToDatalayer(addedProducts.map((bundle) => getProductRowFromIds(reversedProductList, bundle)));
};

export const getRemovedItemsFromLegacyBasket = (removedRow, products) => {
  const { basketRowId, productId } = removedRow;
  const modifiedRow = products.find(({ id }) => id === basketRowId);
  const entireRowRemoved =
    !productId || [modifiedRow.device, modifiedRow.subscription].filter(Boolean).some(({ id }) => id === productId);
  if (entireRowRemoved) {
    return mapBasketRowsToDatalayer([modifiedRow]);
  }
  const removedItem = [...modifiedRow.accessories, ...modifiedRow.addons]
    .reverse() // we remove last item if there are multiple
    .find(({ id }) => id === productId);
  return removedItem ? mapProductToDatalayer(modifiedRow, removedItem) : [];
};

export const generateProductsInLegacyCart = (legacyCart) => {
  const legacyProducts = mapBasketRowsToDatalayer(legacyCart?.products || []);
  const products = (legacyCart?.dcAccessories || []).map(mapLegacyAccessory);

  return [...legacyProducts, ...products];
};

// Mapping legacy cart data structure to purchase event
export const orderSummaryToPurchase = (orderSummary) => {
  const { legacyCart, orderReferenceId } = orderSummary;
  const { minimumPriceSummary, freight } = legacyCart || {};
  const shipping = freight?.price ?? 0;

  return {
    orderReferenceId,
    revenue: minimumPriceSummary + shipping,
    products: generateProductsInLegacyCart(legacyCart),
    shipping,
  };
};
