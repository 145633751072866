import { gql } from '@apollo/client';

const unifiedCartFragment = gql`
  fragment unifiedCartFragment on UnifiedCart {
    offers {
      variant
      image {
        alt
        title
        url
      }
      icon
      id
      psid
      title
      isInstallment
      price
      previousPrice
      conditionText
      priceDescription
      disableImage
      productDescription
      splashPrimaryText
      splashSecondaryText
      isChildOffer
      removePrice
      offerId
      offerType
      subOfferType
      sourceSystem
      removeFromCartOption
      highlightPriceDescription
      upfrontPrice
      monthlyPrice
    }
    priceSummary {
      totalUpfront
      highestMonthlyCost
      totalMinimumPrice
      installments {
        period
        periodicAmount
      }
    }
    tradeInProducts {
      bindingPeriod
      deviceName
      recurringPrice
      row {
        id
      }
      totalTradeInPrice
      tradeIn {
        tradeInCampaignDiscount {
          campaignDeviceId
          campaignHeading
          campaignId
          discount
        }
        devices {
          price
          priceQuoteId
          productNameShort
        }
        tradeInDiscount
      }
    }
    legacyCartId
    dcCartId
    accountLevelDiscount {
      title
      description
      priceDescription
      icon
      discount
    }
  }
`;

export default unifiedCartFragment;
