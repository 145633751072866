import categories from '../constants/categories';
import { OWN_BRAND } from '../constants/custom-brand';

/**
 * Those methods should only be used when mapping accessories to datalayer
 *
 * accessories should have the same structure and almost the same data,
 * it's case-sensitive
 *
 * extra fields are marked as additional fields
 */
export const mapAccessory = (accessory) => {
  const { offering, id } = accessory.selectedVariant;
  return {
    brand: accessory.brand,
    category: categories.ACCESSORY_SHOP,
    id: accessory.id,
    name: accessory.name,
    price: offering?.price?.sellingPrice,
    variant: id,
  };
};

export const mapVariantToImpressionView = (accessory, list, position) => ({
  ...mapAccessory({ ...accessory, selectedVariant: accessory.variants?.[0] || {} }),
  // extra fields for impression view
  list,
  position,
});

export const mapLegacyAccessory = (accessory) => ({
  brand: OWN_BRAND,
  category: categories.ACCESSORY_SHOP,
  id: accessory.displayName,
  name: accessory.displayName,
  price: accessory.price.discountedUpfrontPrice,
  variant: accessory.id,
});

export const mapOfferingToAccessory = (offering) => ({
  brand: OWN_BRAND,
  category: categories.ACCESSORY_SHOP,
  id: offering.product.name,
  name: offering.product.name,
  price: offering.price?.sellingPrice,
  variant: offering.product.id,
});

export const mapBaseAccessory = (accessory, positionInList) => ({
  id: accessory.id,
  name: accessory.name,
  brand: accessory.brand,
  category: categories.ACCESSORY_SHOP,
  position: positionInList,
});
