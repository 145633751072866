/* eslint-disable import/no-import-module-exports */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { BrowserRouter } from 'react-router-dom';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, consumerTheme } from '@3dk/3style';
import { ApolloProvider } from '@apollo/client';
import { loadableReady } from '@loadable/component';
import { QueryClientProvider } from '@3dk/3common';
import createEmotionCache from 'utils/createEmotionCache';
import { FeatureFlagContextProvider, getOptimizelyClient } from './optimizely';
import runtimeConfig from './utils/config';
import App from './App';
import ScrollToTop from './components/ScrollToTop';
import createApolloClient from './apollo/apolloClient';
import { getBypassUnsupportedBrowser, getCustomerTypeCookie } from './utils/cookieHelpers/cookies';
import initI18n from './utils/initI18n';

const createApolloClientAndHandleState = async () => {
  const customerType = getCustomerTypeCookie();
  const cookies = { customerType };

  const { previewHeader } = window;
  const { apolloClient } = await createApolloClient(false, { cookies }, previewHeader);
  const { apolloClient: apolloBusinessClient } = await createApolloClient(false, { cookies }, previewHeader, true);

  delete window.previewHeader;
  delete window.APOLLO_CONSUMER_STATE;
  delete window.APOLLO_BUSINESS_STATE;
  return { consumerClient: apolloClient, businessClient: apolloBusinessClient };
};

loadableReady(async () => {
  const { consumerClient, businessClient } = await createApolloClientAndHandleState();
  const cache = createEmotionCache();

  initI18n(window.globalTexts ?? {});
  delete window.globalTexts;

  // create optimizelyClient and give it dataFile from server
  const optimizelyClient = getOptimizelyClient({
    sdkKey: runtimeConfig.OPTIMIZELY_SDK_KEY,
    dataFile: window.DATA_FILE,
  });
  delete window.DATA_FILE;
  // Solution for preventing hydration issues when using "network-only" or "cache-and-network" fetch policy
  // https://github.com/apollographql/apollo-client/issues/4814
  consumerClient.disableNetworkFetches = true;
  hydrate(
    <HelmetProvider>
      <ApolloProvider client={consumerClient}>
        <BrowserRouter>
          <ThemeProvider theme={consumerTheme}>
            <FeatureFlagContextProvider client={optimizelyClient}>
              <CacheProvider value={cache}>
                {/* Will rest the scroll position to 0 on history
                  This should only be called on the client */}
                <ScrollToTop />
                <QueryClientProvider>
                  <App
                    businessClient={businessClient}
                    userAgent={window.navigator.userAgent}
                    bypassUnsupportedBrowser={getBypassUnsupportedBrowser() === 'true'}
                  />
                </QueryClientProvider>
              </CacheProvider>
            </FeatureFlagContextProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </HelmetProvider>,
    document.getElementById('root'),
    () => {
      consumerClient.disableNetworkFetches = false;
      const muiStyles = document.querySelector('#mui-css-server-side');
      if (muiStyles) {
        muiStyles.parentNode.removeChild(muiStyles);
      }
    },
  );
});

if (module.hot) {
  module.hot.accept();
}
