import { OWN_BRAND } from '../constants/custom-brand';

/**
 * Those methods should only be used when mapping subscriptions to datalayer
 *
 * subscriptions should have the same structure and almost the same data,
 * it's case-sensitive
 */
export const mapSubscriptionToDayaLayer = (subscription, simAlone = false, purchaseType = undefined) => ({
  brand: OWN_BRAND,
  category: subscription.type,
  id: subscription.label,
  name: `SIM - ${subscription.label}`,
  price: subscription.recurringPrice,
  webId: subscription.id,
  variant: `SIM-Alone: ${simAlone}`,
  purchaseType,
});

export const mapSubscriptionPageToDayaLayer = (subscriptionPage) => ({
  brand: OWN_BRAND,
  category: subscriptionPage.type,
  id: subscriptionPage.title,
  name: `SIM - ${subscriptionPage.title}`,
  price: subscriptionPage.price?.installment,
  webId: subscriptionPage.id,
  variant: `SIM-Alone: true`,
  purchaseType: undefined,
});

export const generateSubscriptionInLegacyCart = (device, subscription, isEmbeddableSim) => {
  if (!subscription) return null;
  const { subscriptionType, displayName, id, price, options } = subscription;

  return {
    brand: OWN_BRAND,
    category: subscriptionType,
    id: displayName,
    name: `SIM - ${displayName}`,
    price: price.discountedRecurringPrice,
    webId: id,
    variant: !device ? 'SIM-Alone: true' : 'SIM-Alone: false',
    purchaseType: options.subscriptionSaleType,
    // extra fields
    eSim: isEmbeddableSim,
    quantity: 1,
  };
};
