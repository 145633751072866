import PropTypes from 'prop-types';
import assetPropType from './asset';
import pagePropTypes from './page';
import LINK_TYPES from '../../constants/linkTypes';

const linkPropType = {
  text: PropTypes.string,
  title: PropTypes.string,
  linkType: PropTypes.oneOf([
    LINK_TYPES.URL,
    LINK_TYPES.ENTRY,
    LINK_TYPES.ASSET,
    LINK_TYPES.ONE_CLICK,
    LINK_TYPES.TRADE_IN,
    LINK_TYPES.ANCHOR,
  ]),
  url: PropTypes.string,
  externalLink: PropTypes.string,
  internalLink: pagePropTypes,
  assetLink: assetPropType,
  buttonColor: PropTypes.string,
  fontColor: PropTypes.string,
  className: PropTypes.string,
  oneClickProducts: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.arrayOf(PropTypes.string),
      isMainProduct: PropTypes.bool,
    }),
  ),
  anchorLink: PropTypes.string,
};

export const LinkDefaultValues = {
  buttonColor: '',
  fontColor: '',
  internalLink: null,
  className: null,
  url: null,
  linkType: null,
  oneClickProducts: [],
  anchorLink: null,
};

export default linkPropType;
