import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { error as logError } from '@hi3g-access/client-logger';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, InMemoryCache, gql, ApolloLink, from } from '@apollo/client';
import fetch from 'isomorphic-fetch';
import cleanDeep from 'clean-deep';
import { setContext } from '@apollo/client/link/context';
import runtimeConfig from '../utils/config';
import errorHandler from '../utils/errorHandler';

const serverSideUri = runtimeConfig.RAZZLE_GRAPHQL_SERVER_ENDPOINT;
const clientSideUri = runtimeConfig.RAZZLE_GRAPHQL_CLIENT_ENDPOINT;
const credentials = 'include';

const createApolloClient = async (isSSR, { cookies } = {}, previewHeader = '', useBusiness = false) => {
  const { customerType = '' } = cookies;

  const uri = isSSR ? serverSideUri : clientSideUri;

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          accessoriesV4: {
            keyArgs: ['categories', 'colours', 'brands', 'compatibleHardware', '@connection', ['key']],
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing, incoming, { args }) {
              const accessories = existing?.accessories?.slice(0) ?? [];
              const start = args ? args.offset : accessories.length;
              const end = start + (incoming?.accessories.length ?? 0);
              for (let i = start; i < end; i += 1) {
                accessories[i] = incoming?.accessories[i - start];
              }
              return { ...incoming, accessories: [...accessories] };
            },
          },
          accessoryBySlugV4(_, { args, toReference }) {
            return toReference({
              __typename: 'AccessoryV4',
              slug: args.slug,
            });
          },
          accessoriesAgent: {
            keyArgs: ['categories', 'colours', 'brands', 'compatibleHardware', '@connection', ['key']],
            // Concatenate the incoming list items with
            // the existing list items.
            merge(existing, incoming, { args }) {
              const accessories = existing?.accessories?.slice(0) ?? [];
              const start = args ? args.offset : accessories.length;
              const end = start + (incoming?.accessories.length ?? 0);
              for (let i = start; i < end; i += 1) {
                accessories[i] = incoming?.accessories[i - start];
              }
              return { ...incoming, accessories: [...accessories] };
            },
          },
          accessoryBySlugAgent(_, { args, toReference }) {
            return toReference({
              __typename: 'AccessoryV4',
              slug: args.slug,
            });
          },
        },
      },
      AccessoryV4: {
        keyFields: ['slug'],
      },
      OrderInfo: {
        keyFields: [],
      },
      // Different instances of Legacy_DevicePageInstallmentPlan and Legacy_DevicePageVariantSize differ only by periodAmount/upfrontPrice
      // Apollo can therefore not properly distinguish between them without adding these properties to their keyFields
      Legacy_DevicePageInstallmentPlan: {
        keyFields: ['id', 'periodAmount'],
      },
      Legacy_DevicePageVariantSize: {
        keyFields: ['id', 'upfrontPrice'],
      },
      Cart: {
        keyFields: [],
      },
      LegacyCart: {
        keyFields: [],
      },
      LegacyDevice: {
        keyFields: ['id', 'price', ['bindingPeriod', 'discountedUpfrontPrice', 'discountedRecurringPrice']],
      },
      LegacySubscription: {
        keyFields: ['id', 'basketRowId'],
      },
      UnifiedCartOffer: {
        keyFields: ['id', 'offerId'],
      },
      ProductPageBase_VariantContent: {
        fields: {
          images: {
            merge(existing, incoming) {
              return incoming || existing;
            },
          },
        },
      },
    },
    possibleTypes: {
      SeoFields: ['Page', 'ListPage', 'ContentPage', 'ExternalPage', 'ProductPage_SubscriptionPage'],
      ContentfulEntity: [
        'Contentful_SubscriptionCard',
        'IFrame',
        'Carousel',
        'NotificationBar',
        'ProductList',
        'DeviceList',
        'PageHeader',
        'FaqEntry',
        'ProductSlider',
        'RoamingCountriesDropdown',
        'GridContainer',
        'SimpleImage',
        'SimpleText',
        'VideoBlock',
        'StoreList',
        'OneBlock',
        'Faq',
        'IconBlock',
        'RowContainer',
        'SearchBar',
        'LegalText',
        'FwaContainer',
        'FamilySlider',
        'CountryList',
      ],
    },
  });

  if (!isSSR) {
    if (useBusiness) {
      cache.restore(window.APOLLO_BUSINESS_STATE);
    } else {
      cache.restore(window.APOLLO_CONSUMER_STATE);
    }
  }

  cache.writeQuery({
    query: gql`
      query GetCustomerType {
        customerType @client
      }
    `,
    data: {
      customerType,
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) errorHandler.logGraphQLErrors(graphQLErrors);
    if (networkError) logError(`[Network error]: ${networkError}`);
  });

  const removeNullLink = new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      cleanDeep(response, {
        emptyArrays: false,
        emptyObjects: false,
        emptyStrings: false,
      });
      return response;
    }),
  );

  const batchLink = new BatchHttpLink({
    uri,
    credentials,
    ...(isSSR && { fetch }),
    headers: {
      cookie: Object.keys(cookies)
        .map((key) => `${key}=${[key]}`)
        .join('; '),
      ...(previewHeader ? { 'x-preview': previewHeader } : {}),
      ...(useBusiness ? { 'x-space': 'business' } : {}),
    },
  });

  const headerLink = setContext((operation, previousContext) => {
    const { headers } = previousContext;
    return {
      ...previousContext,
      headers: {
        ...headers,
        ...(previewHeader ? { 'x-preview': previewHeader } : {}),
        ...(useBusiness ? { 'x-space': 'business' } : {}),
      },
    };
  });

  return {
    apolloClient: new ApolloClient({
      ssrMode: isSSR,
      link: from([errorLink, removeNullLink, headerLink, batchLink]),
      cache,
      // If you want to use @client without local resolvers, you must pass empty object.
      resolvers: {},
    }),
  };
};

export default createApolloClient;
