import { gql } from '@apollo/client';
import linkFragment from './link';

const footerSectionFragment = gql`
  fragment FooterSectionFragment on FooterSection {
    header
    links {
      ...LinkFragment
    }
  }
  ${linkFragment}
`;

export default footerSectionFragment;
