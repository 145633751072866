import { error as logError } from '@hi3g-access/client-logger';
import { useLocation } from 'react-router-dom';
import ErrorPage from './ErrorPage';

const pageTitle = 'Siden kan ikke vises (404)';

const ErrorPage404 = () => {
  const location = useLocation();
  logError({ code: 404, error: `404 - page ${location.pathname}` });
  const text = 'Siden, du leder efter, \n \n eksisterer desværre ikke';
  return <ErrorPage code={404} text={text} pageTitle={pageTitle} />;
};

export default ErrorPage404;
